<div clas="flex">
	<div class="w-full">
		<mat-form-field class="w-full">
			<mat-label>{{ label() }}</mat-label>
			<input
				matInput
				class="w-full"
				data-cy="input-field"
				[required]="required()"
				[type]="$inputType()"
				[attr.inputmode]="$inputMode()"
				[formControl]="control()"
				[mask]="$mask()"
				[decimalMarker]="'.'"
				[thousandSeparator]="','"
				[hiddenInput]="$hideProtected()"
				[allowNegativeNumbers]="$maskConfiguration()?.config?.allowNegativeNumbers || false"
				[apm]="$maskConfiguration()?.config?.apm || false"
				[clearIfNotMatch]="$maskConfiguration()?.config?.clearIfNotMatch || false"
				[dropSpecialCharacters]="$maskConfiguration()?.config?.dropSpecialCharacters || false"
				[keepCharacterPositions]="$maskConfiguration()?.config?.keepCharacterPositions || false"
				[leadZeroDateTime]="$maskConfiguration()?.config?.leadZeroDateTime || true"
				[leadZero]="$maskConfiguration()?.config?.leadZero || false"
				[patterns]="$maskConfiguration()?.config?.patterns"
				[placeholder]="$maskConfiguration()?.config?.placeHolderCharacter || ''"
				[prefix]="$maskConfiguration()?.config?.prefix || ''"
				[showTemplate]="$maskConfiguration()?.config?.showTemplate || false"
				[suffix]="$maskConfiguration()?.config?.suffix || ''"
				[triggerOnMaskChange]="$maskConfiguration()?.config?.triggerOnMaskChange || false"
				(paste)="onPaste($event)"
				(blur)="$onBlur.emit($event)"
				(focus)="$onFocus.emit($event)" />

			@if (showProtectedToggle() && maskType() === 'ssn') {
				<button
					type="button"
					mat-icon-button
					matSuffix
					class="protected-field-toggle"
					(click)="toggleShowProtected()">
					<i
						class="far"
						[ngClass]="$hideProtected() ? 'fa-eye' : 'fa-eye-slash'"
						[attr.aria-label]="$hideProtected() ? 'Show' : 'Hide'"></i>
				</button>
			}
		</mat-form-field>
		<div class="m-0 pl-2.5">
			@if (hint()) {
				<mat-hint>{{ hint() }}</mat-hint>
			}

			<!-- ng content can be used for hints, errors, etc. -->
			<ng-content></ng-content>

			@if (errorMessagesEnabled()) {
				@if (control()?.touched && control()?.hasError('required')) {
					<mat-error>{{ requiredErrorMessage() }}</mat-error>
				}
				@if (control()?.touched && control()?.hasError('mask') && !control()?.hasError('required')) {
					<mat-error>{{ maskErrorMessage() }}</mat-error>
				}
			}
		</div>
	</div>
</div>
