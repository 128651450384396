<form [formGroup]="applicantForm">
	<div class="flex flex-col gap-4">
		<app-divider
			classes="mt-4 mb-4"
			text="Basic Information"></app-divider>
		<mat-form-field>
			<mat-label>First Name</mat-label>
			<input
				formControlName="firstName"
				matInput
				required
				data-cy="input-field"
				autocomplete="off"
				spellcheck="false"
				maxlength="40" />
			@if (applicantForm.get('firstName').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s first name.</mat-error>
			}
			@if (applicantForm.get('firstName').hasError('maxlength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s first name must be 40 characters or less.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Middle Name</mat-label>
			<input
				formControlName="middleName"
				matInput
				data-cy="input-field"
				autocomplete="off"
				spellcheck="false"
				maxlength="40" />
			@if (applicantForm.get('middleName').hasError('maxlength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s middle name must be 40 characters or less.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Last Name</mat-label>
			<input
				formControlName="lastName"
				matInput
				required
				data-cy="input-field"
				autocomplete="off"
				spellcheck="false"
				maxlength="40" />
			@if (applicantForm.get('lastName').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s last name.</mat-error>
			}
			@if (applicantForm.get('lastName').hasError('maxlength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s last name must be 40 characters or less.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>{{ $isPersonal() ? 'Email' : 'Personal Email' }}</mat-label>
			<input
				matInput
				required
				formControlName="email"
				type="email"
				inputmode="email"
				data-cy="input-field" />
			@if (applicantForm.get('email').hasError('required') || applicantForm.get('email').hasError('email')) {
				<mat-error>Please include a valid email for {{ $applicantNameOrDefault() }}.</mat-error>
			}
		</mat-form-field>
		<app-masked-input
			maskType="phone"
			label="Phone Number"
			hint="This phone number will be used if you need to activate your debit card or reset your password."
			requiredErrorMessage="Please include {{ $applicantNameOrDefault() }}'s phone number."
			maskErrorMessage="Please include a valid phone number for {{ $applicantNameOrDefault() }}"
			[control]="applicantForm.get('phoneNumber') | formControl">
		</app-masked-input>

		@if (!$isPersonal()) {
			<app-divider
				classes="mt-4 mb-4"
				text="Business Role"></app-divider>

			<div formGroupName="businessRoleDetails">
				<mat-form-field>
					<mat-label>Primary Role</mat-label>
					<input
						formControlName="businessRole"
						maxlength="40"
						matInput
						required
						data-cy="input-field" />
					@if ($businessRoleDetails().get('businessRole').hasError('required')) {
						<mat-error>Please enter a primary role with the business.</mat-error>
					}
					@if ($businessRoleDetails().get('businessRole').hasError('maxlength')) {
						<mat-error>Please enter a primary role that is 40 characters or less.</mat-error>
					}
				</mat-form-field>

				<div
					class="mb-4 flex justify-between"
					data-cy="toggle-container">
					<div>
						<mat-label
							id="ownership-question"
							class="mr-auto toggle-label"
							>Do you own 25% or more of this company?</mat-label
						>
					</div>
					<div>
						<mat-label class="mx-3 toggle-label">No</mat-label>
						<mat-slide-toggle
							aria-labelledby="ownership-question"
							formControlName="isBeneficialOwner"
							class="nbkc-blue"
							data-cy="toggle-field">
						</mat-slide-toggle>
						<mat-label class="mx-3 toggle-label">Yes</mat-label>
					</div>
				</div>

				@if (!$isPrimaryApplicant()) {
					<div
						class="mb-4 flex justify-between"
						data-cy="toggle-container">
						<div>
							<mat-label
								class="mr-auto toggle-label"
								id="authorized-signer-question"
								>Will you be authorized to sign on this account?</mat-label
							>
						</div>
						<div>
							<mat-label class="mx-3 toggle-label">No</mat-label>
							<mat-slide-toggle
								formControlName="isAuthorizedSigner"
								aria-labelledby="authorized-signer-question"
								class="nbkc-blue"
								data-cy="toggle-field">
							</mat-slide-toggle>
							<mat-label class="mx-3 toggle-label">Yes</mat-label>
						</div>
					</div>
				}
			</div>
		}

		<app-divider
			classes="mt-4 mb-4"
			text="Address"></app-divider>
		<div formArrayName="addresses">
			<!--		Physical Address  -->
			<app-address-form
				(childBlur)="saveApplication()"
				[$addressForm]="getAddressGroup(0)"
				[$applicantNameOrDefault]="$applicantNameOrDefault()">
			</app-address-form>

			<div
				class="flex justify-between mt-4 mb-4"
				data-cy="toggle-container">
				<div>
					<mat-label
						id="mailing-address-question"
						class="toggle-label"
						>Is this also the mailing address?</mat-label
					>
				</div>
				<div class="'text-base flex justify-end mb-4'">
					<mat-label class="mx-3 toggle-label">No</mat-label>
					<!--				standalone: true keeps toggle out of form controls, tabIndex allows tabbing to this-->
					<mat-slide-toggle
						[(ngModel)]="$usePhysicalAddressAsMailingAddress"
						(ngModelChange)="onChangeAddressType()"
						[ngModelOptions]="{ standalone: true }"
						aria-labelledby="mailing-address-question"
						tabIndex="0"
						class="nbkc-blue"
						data-cy="toggle-field">
					</mat-slide-toggle>
					<mat-label class="mx-3 toggle-label">Yes</mat-label>
				</div>
			</div>

			<!--		Mailing Address -->
			@if (!$usePhysicalAddressAsMailingAddress()) {
				<app-address-form
					[$addressForm]="getAddressGroup(1)"
					[$applicantNameOrDefault]="$applicantNameOrDefault()">
				</app-address-form>
			}
		</div>

		<app-divider
			classes="mt-4 mb-4"
			text="Identity"></app-divider>
		<div
			class="flex flex-col gap-4"
			[formGroup]="identification">
			<mat-form-field>
				<mat-label>Primary ID Type</mat-label>
				<mat-select
					formControlName="type"
					required
					data-cy="select-field">
					<mat-option [value]="IdentificationType.DriversLicense">U.S. Driver's License</mat-option>
					<mat-option [value]="IdentificationType.Passport">Passport</mat-option>
					<mat-option [value]="IdentificationType.StateIdCard">U.S. State ID Card</mat-option>
				</mat-select>
				@if ($identification().get('type').hasError('required')) {
					<mat-error>Please select an identification type for {{ $applicantNameOrDefault() }}.</mat-error>
				}
			</mat-form-field>
			@switch ($idType()) {
				@case (IdentificationType.Passport) {
					<mat-form-field>
						<mat-label>Passport ID Number</mat-label>
						<input
							formControlName="number"
							matInput
							required
							[attr.tabindex]="0"
							maxlength="25"
							data-cy="input-field" />
						@if ($identification().get('number').hasError('required')) {
							<mat-error>Please include {{ $applicantNameOrDefault() }}'s passport number.</mat-error>
						}
						@if ($identification().get('number').hasError('maxlength')) {
							<mat-error>{{ $applicantNameOrDefault() }}'s passport number must be 25 characters or less.</mat-error>
						}
					</mat-form-field>
					<app-date-input
						label="Passport Expiration Date"
						[dateControl]="$identification().get('expirationDate') | formControl"
						[required]="true">
						@if ($identification().get('expirationDate').touched && $identification().get('expirationDate').hasError('required')) {
							<span>Please include {{ $applicantNameOrDefault() }}'s passport expiration date.</span>
						}
						@if ($identification().get('expirationDate').touched && $identification().get('expirationDate').hasError('maxIdExpirationDate')) {
							<span>Please include a date that is less than 50 years from today for {{ $applicantNameOrDefault() }}'s passport.</span>
						}
						@if ($identification().get('expirationDate').touched && $identification().get('expirationDate').hasError('expiredId')) {
							<span>{{ $applicantNameOrDefault() }}'s passport must not be expired.</span>
						}
					</app-date-input>
					<mat-form-field>
						<mat-label>Passport Issuing Country</mat-label>
						<mat-select
							formControlName="issuingCountry"
							required
							[attr.tabindex]="0"
							data-cy="select-field">
							@for (country of $lookups().Countries; track country.value) {
								<mat-option [value]="country.value">{{ country.display }}</mat-option>
							}
						</mat-select>
						@if ($identification().get('issuingCountry').hasError('required')) {
							<mat-error>Please include the issuing country for {{ $applicantNameOrDefault() }}'s passport.</mat-error>
						}
					</mat-form-field>
				}
				@case (IdentificationType.DriversLicense) {
					<mat-form-field>
						<mat-label>Driver's License Issuing State</mat-label>
						<mat-select
							formControlName="issuingState"
							required
							[attr.tabindex]="0"
							data-cy="select-field">
							@for (state of $lookups().States; track state.value) {
								<mat-option [value]="state.value">{{ state.display }}</mat-option>
							}
						</mat-select>
						@if ($identification().get('issuingState').hasError('required')) {
							<mat-error>Please select a state for {{ $applicantNameOrDefault() }}'s driver's license.</mat-error>
						}
					</mat-form-field>
					<mat-form-field>
						<mat-label>Driver's License Number</mat-label>
						<input
							formControlName="number"
							matInput
							required
							[attr.tabindex]="0"
							maxlength="25"
							data-cy="input-field" />
						@if ($identification().get('number').hasError('required')) {
							<mat-error>Please include {{ $applicantNameOrDefault() }}'s driver's license number.</mat-error>
						}
						@if ($identification().get('number').hasError('maxlength')) {
							<mat-error>{{ $applicantNameOrDefault() }}'s driver's license number must be 25 characters or less.</mat-error>
						}
					</mat-form-field>
					<app-date-input
						[dateControl]="$identification().get('expirationDate') | formControl"
						label="Driver's License Expiration Date"
						[required]="true">
						@if ($identification().get('expirationDate').touched && $identification().get('expirationDate').hasError('required')) {
							<span>Please include {{ $applicantNameOrDefault() }}'s driver's license expiration date.</span>
						}
						@if ($identification().get('expirationDate').touched && $identification().get('expirationDate').hasError('maxIdExpirationDate')) {
							<span>Please include a valid expiration date (MM/DD/YYYY) for {{ $applicantNameOrDefault() }}'s driver's license.</span>
						} @else if (
							$identification().get('expirationDate').touched &&
							$identification().get('expirationDate').touched &&
							$identification().get('expirationDate').hasError('expiredId')
						) {
							<span>{{ $applicantNameOrDefault() }}'s driver's license must not be expired.</span>
						}
					</app-date-input>
				}
				@case (IdentificationType.StateIdCard) {
					<mat-form-field>
						<mat-label>ID Issuing State</mat-label>
						<mat-select
							formControlName="issuingState"
							[attr.tabindex]="0"
							required
							data-cy="select-field">
							@for (state of $lookups().States; track state.value) {
								<mat-option [value]="state.value">{{ state.display }}</mat-option>
							}
						</mat-select>
						@if ($identification().get('issuingState').hasError('required')) {
							<mat-error>Please select a state for {{ $applicantNameOrDefault() }}'s state id card.</mat-error>
						}
					</mat-form-field>
					<mat-form-field>
						<mat-label>ID Number</mat-label>
						<input
							formControlName="number"
							matInput
							[attr.tabindex]="0"
							maxlength="25"
							required
							data-cy="input-field" />
						@if ($identification().get('number').hasError('required')) {
							<mat-error>Please include {{ $applicantNameOrDefault() }}'s state identification card number.</mat-error>
						}
						@if ($identification().get('number').hasError('maxlength')) {
							<mat-error>{{ $applicantNameOrDefault() }}'s state id card number must be 25 characters or less.</mat-error>
						}
					</mat-form-field>
					<app-date-input
						label="State ID Expiration Date"
						[dateControl]="$identification().get('expirationDate') | formControl"
						[required]="true">
						@if ($identification().get('expirationDate').touched && $identification().get('expirationDate').hasError('required')) {
							<span>Please include {{ $applicantNameOrDefault() }}'s state ID expiration date.</span>
						}
						@if (
							$identification().get('expirationDate').touched &&
							($identification().get('expirationDate').hasError('maxIdExpirationDate') ||
								$identification().get('expirationDate').hasError('expiredId'))
						) {
							<span>Please include a valid expiration date (MM/DD/YYYY) for {{ $applicantNameOrDefault() }}'s state ID.</span>
						} @else if ($identification().get('expirationDate').touched && $identification().get('expirationDate').hasError('expiredId')) {
							<span>{{ $applicantNameOrDefault() }}'s state ID must not be expired.</span>
						}
					</app-date-input>
				}
			}
		</div>
		<app-date-input
			label="Date of Birth"
			[dateControl]="applicantForm.get('dateOfBirth') | formControl"
			[required]="true">
			@if (
				applicantForm.get('dateOfBirth').touched &&
				(applicantForm.get('dateOfBirth').hasError('required') || applicantForm.get('dateOfBirth').hasError('maxAge'))
			) {
				<span>Please include a valid date of birth (MM/DD/YYYY) for {{ $applicantNameOrDefault() }}.</span>
			} @else if (applicantForm.get('dateOfBirth').hasError('minAge')) {
				<span>{{ $applicantNameOrDefault() }} must be 18 or older.</span>
			}
		</app-date-input>
		<div formGroupName="taxIdentification">
			<app-tax-identification
				[$taxIdentificationFormGroup]="$taxIdentification()"
				[$applicantNameOrDefault]="$applicantNameOrDefault()"></app-tax-identification>
		</div>
		<mat-form-field>
			<mat-label>Mother's Maiden Name</mat-label>
			<input
				formControlName="mothersMaidenName"
				(blur)="saveApplication()"
				matInput
				required
				maxlength="40"
				data-cy="input-field"
				autocomplete="off"
				spellcheck="false" />
			@if (applicantForm.get('mothersMaidenName').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s mother's maiden name.</mat-error>
			}
			@if (applicantForm.get('mothersMaidenName').hasError('maxlength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s mother's maiden name must be 40 characters or less.</mat-error>
			}
		</mat-form-field>

		<app-divider
			classes="mt-4 mb-4"
			text="Additional Details"></app-divider>

		<mat-form-field>
			<mat-label>What is your primary income source?</mat-label>
			<mat-select
				formControlName="incomeSource"
				required
				data-cy="select-field">
				@for (source of $lookups().IncomeSources; track source.value) {
					<mat-option [value]="source.value">{{ source.display }}</mat-option>
				}
			</mat-select>
			@if (applicantForm.get('incomeSource').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s income source.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>What is your occupation?</mat-label>
			<mat-select
				formControlName="occupation"
				required
				data-cy="select-field">
				@for (occupation of $lookups().Occupations; track occupation.value) {
					<mat-option [value]="occupation.value">{{ occupation.display }}</mat-option>
				}
			</mat-select>
			@if (applicantForm.get('occupation').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s occupation.</mat-error>
			}
		</mat-form-field>

		@if (!$isPersonal() && $isPrimaryApplicant()) {
			<div class="form-group flex-column mb-4">
				<div class="flex">
					<mat-checkbox
						[formControl]="$isControlIndividual()"
						aria-labelledby="controlling-manager-question"
						class="big-checkbox wrap-checkbox-text"
						required
						data-cy="check-field"></mat-checkbox>
					<label
						class="wrap-text"
						id="controlling-manager-question">
						I confirm that I am the Controlling Manager and I have the right to perform financial transactions and activity for this business.
					</label>
				</div>
				@if ($isControlIndividual().touched && $isControlIndividual().hasError('required')) {
					<div class="custom-error">Please indicate whether or not {{ $applicantNameOrDefault() }} is a control individual.</div>
				}
			</div>
		}
		<div class="form-group flex-column mb-4">
			<div class="flex">
				<mat-checkbox
					formControlName="consentsToTaxRequirements"
					class="big-checkbox wrap-checkbox-text"
					required
					data-cy="check-field"
					aria-labelledby="confirmation-question {{
						$isPrimaryApplicant ? 'primary-confirmation-list' : 'secondary-confirmation-list'
					}}"></mat-checkbox>

				<label class="confirmation">
					<span id="confirmation-question">I confirm that:</span>
					@if ($isPrimaryApplicant) {
						<ul
							id="primary-confirmation-list"
							class="list-disc list-inside">
							<li>I am a U.S. citizen or Resident Alien.</li>
							<li>I reside in the U.S. or U.S. territory for the majority of each calendar year.</li>
							<li>I am not subject to backup withholding by the IRS.</li>
						</ul>
					} @else {
						<ul
							class="list-disc list-inside"
							id="secondary-confirmation-list">
							<li>{{ $secondaryApplicantNameOrDefault() }} is a U.S. citizen or Resident Alien.</li>
							<li>{{ $secondaryApplicantNameOrDefault() }} resides in the U.S. or U.S. territory for the majority of each calendar year.</li>
							<li>{{ $secondaryApplicantNameOrDefault() }} is not subject to backup withholding by the IRS.</li>
						</ul>
					}
				</label>
			</div>
			@if (
				$isPrimaryApplicant &&
				applicantForm.get('consentsToTaxRequirements').touched &&
				applicantForm.get('consentsToTaxRequirements').hasError('required')
			) {
				<div class="custom-error">Please verify residency and tax status for {{ $applicantNameOrDefault() }}.</div>
			} @else if (
				!$isPrimaryApplicant &&
				applicantForm.get('consentsToTaxRequirements').touched &&
				applicantForm.get('consentsToTaxRequirements').hasError('required')
			) {
				<div class="custom-error">Please verify residency and tax status for {{ $secondaryApplicantNameOrDefault() }}.</div>
				y
			}
		</div>
	</div>
</form>
