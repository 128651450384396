<mat-card
	[attr.tabindex]="selectable() ? 0 : null"
	class="w-full overflow-ellipsis max-w-full rounded-xl p-2 shadow-card"
	[ngClass]="{
		'cursor-pointer': !!cardActionInfo(),
		'selected-card': $selected(),
		'selectable-accessibility': selectable(),
	}"
	(click)="selectable() ? toggleSelected() : null">
	@if (!hideHeader()) {
		<mat-card-header
			class="flex"
			[ngClass]="showContent() ? 'mb-2' : 'mb-4'">
			<div class="flex align-center justify-between flex-grow">
				<div class="flex w-full">
					@if (headerIcon()) {
						<mat-icon
							class="fal mr-4"
							aria-hidden="true"
							[ngClass]="[headerIcon(), xlHeader() ? 'text-3xl' : 'text-2xl', headerIconColor() ? 'nbkc-' + headerIconColor() : '']"></mat-icon>
					}
					<div
						[ngClass]="{
							'flex-column': !!subtitle(),
							'flex justify-center items-center h-full': !subtitle(),
						}">
						<div [ngClass]="xlHeader() ? 'text-3xl' : 'text-2xl'">{{ mainTitle() }}</div>
						@if (subtitle()) {
							<div mat-card-subtitle>
								{{ subtitle() }}
								@if (secondSubtitle()) {
									-- <span>{{ secondSubtitle() }}</span>
								}
							</div>
						}
					</div>
				</div>
				@if (headerRate()) {
					<div class="flex items-center nbkc-blue pl-4">
						<span class="text-3xl mr-1 font-semibold">{{ headerRate() | number: '1.2-2' }}</span>
						<div class="m-0 p-0 font-normal">
							<div class="text-sm transform translate-y-1.5">APY</div>
							<div class="text-xl">%</div>
						</div>
					</div>
				}
			</div>
			@if (hasCornerBanner()) {
				<div class="triangle"></div>
				<i class="star fas fa-star"></i>
			}
		</mat-card-header>
	}

	@if (showContent()) {
		<mat-card-content>
			<!--Simple body text -->
			@if (bodyText()) {
				<p class="pt-2 overflow-ellipsis{{ textBodyColor() ? 'nbkc-' + textBodyColor() : 'nbkc-dark-gray' }}">
					{{ bodyText() }}
				</p>
			}
			<!--Any content, including a widget -->
			<ng-content></ng-content>
		</mat-card-content>
	}

	@if (cardActions().length || cardActionInfo()) {
		<mat-card-actions align="end">
			<!--Add words (Continue, Start) to action area without a button-->
			<p class="card-action-info justify-items-stretch">{{ cardActionInfo() }}</p>
			<!--Add buttons to app-card with cardAction directive-->
			@if (cardActions().length) {
				@for (action of cardActions(); track action; let i = $index) {
					<button
						mat-button
						size="large"
						class="mr-4"
						(click)="onCardActionClicked(action)"
						[color]="i === 0 && cardActions().length > 1 ? 'warn' : 'primary'">
						{{ action }}
					</button>
				}
			}
		</mat-card-actions>
	}
</mat-card>
