import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { AppCardComponent } from '@experience/app/components-new/card/app-card.component';
import { IndividualApplicantFormComponent } from '@experience/app/components-new/individual-applicant/individual-applicant-form/individual-applicant-form.component';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { NextTodoButtonComponent } from '../next-button/next-todo-button.component';
import { MatButton } from '@angular/material/button';
import { AppRoutingService } from '@experience/app/services/app-routing.service';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { KnownRoutes } from '@experience/app/constants/known-routes';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-individual-applicant',
	standalone: true,
	imports: [AppCardComponent, IndividualApplicantFormComponent, AppTodoHeaderComponent, NextTodoButtonComponent, MatButton],
	templateUrl: './individual-applicant-card.component.html',
	styleUrl: './individual-applicant-card.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndividualApplicantCardComponent implements OnInit {
	private route = inject(ActivatedRoute);
	private auth = inject(AuthenticationUtility);
	private $authContext = toSignal(this.auth.authenticationContext$);
	private internalStateService = inject(InternalStateService);
	private routingService = inject(AppRoutingService);
	private applicationStateService = inject(ApplicationStateService);
	private applicationApiService = inject(ApplicationsApiService);
	private destroyRef = inject(DestroyRef);
	private subscription = new Subscription();
	$application = this.applicationStateService.$application;
	$applicantEmail = computed<string>(() => '');
	$businessRole = computed<string>(() => '');
	$isPersonal = this.internalStateService.$isPersonal;
	$applicantIndex = signal<number>(null);
	$applicantTitle = computed<string>(() => {
		let defaultTitle = 'Additional Individual';
		if (this.$applicantIndex() === 0) {
			defaultTitle = this.$isPersonal() ? 'Primary Owner' : 'Primary Individual';
		}

		const currentApplicant = this.$application().applicants?.[this.$applicantIndex()];
		if ((currentApplicant?.firstName || currentApplicant?.lastName) && (currentApplicant.firstName + currentApplicant.lastName).trim().length > 0) {
			return `${currentApplicant.firstName} ${currentApplicant.lastName}`;
		} else {
			return defaultTitle;
		}
	});

	ngOnInit() {
		this.subscribeToRouteParams();

		if (this.$isPersonal() && this.$applicantIndex() === 0) {
			this.$applicantEmail = computed(() => this.$authContext()?.user?.email ?? '');
		} else {
			this.$applicantEmail = computed(() => this.$application().applicants?.[this.$applicantIndex()]?.email ?? '');
		}
		this.$businessRole = computed(() => this.$application().applicants?.[this.$applicantIndex()]?.businessRoleDetails?.businessRole ?? '');

		this.destroyRef.onDestroy(() => this.subscription.unsubscribe());
	}

	onClickJointOwnerNextButton(): void {
		this.applicationApiService.saveApplication(this.$application()).subscribe();
		this.routingService.routeToApplicationScreen(KnownRoutes.AddApplicants);
	}

	subscribeToRouteParams(): void {
		this.subscription.add(
			this.route.paramMap.subscribe((params) => {
				const index = +params.get('applicantIndex') || 0;
				this.$applicantIndex.set(index);
			}),
		);
	}
}
