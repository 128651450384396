import { NgForOf, NgIf } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { LoadingService } from '@experience/app/services/loading.service';
import { AppCardComponent } from '../card/app-card.component';
import { AppDividerComponent } from '../divider/app-divider.component';
import { AppDocumentUploadComponent } from '../app-document-upload/app-document-upload.component';
import { ApplicantKind } from '@experience/app/models/enums/applicant-kind';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { AlloyIdentityVerificationComponent } from '../alloy-identity-verification/alloy-identity-verification.component';

@Component({
	selector: 'app-manual-review',
	standalone: true,
	imports: [MatCardModule, NgForOf, NgIf, AppCardComponent, AppDividerComponent, AppDocumentUploadComponent, AlloyIdentityVerificationComponent],
	templateUrl: './app-manual-review.component.html',
	styleUrl: './app-manual-review.component.scss',
})
export class ManualReviewComponent {
	loadingService = inject(LoadingService);
	applicationStateService = inject(ApplicationStateService);
	$qualificationResults = this.applicationStateService.$qualification;

	primaryApplicantEmail = computed(() => {
		const primaryApplicant = this.applicationStateService.$application()?.applicants?.find((applicant) => applicant.kind === ApplicantKind.Primary);
		return primaryApplicant?.email;
	});

	nextStepsBodyText = computed(() => `We will email you at ${this.primaryApplicantEmail()} after we have reviewed your documents and set up your account.`);
}
