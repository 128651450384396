import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { AppCardComponent } from '@experience/app/components-new/card/app-card.component';
import { DecimalPipe } from '@angular/common';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { ApplicationAccount } from '@experience/app/models/onboarding/application-account.model';
import { NextTodoButtonComponent } from '@experience/app/components-new/next-button/next-todo-button.component';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { ProductSelection } from '@experience/app/models-ui/product-selection/product-selection-model';

@Component({
	selector: 'app-product-selection',
	standalone: true,
	imports: [AppCardComponent, AppTodoHeaderComponent, DecimalPipe, NextTodoButtonComponent],
	templateUrl: './product-selection.component.html',
	styleUrl: './product-selection.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSelectionComponent {
	readonly applicationStateService = inject(ApplicationStateService);
	readonly applicationApiService = inject(ApplicationsApiService);
	readonly internalStateService = inject(InternalStateService);

	readonly $products = computed<ProductSelection[]>(() => {
		const accounts = this.applicationStateService.$application().accounts;
		const products = this.internalStateService.$products().map(
			(p) =>
				({
					id: p.id,
					name: p.name,
					displayText: p.displayText,
					rate: p.rate,
					selected: accounts ? accounts.some((a) => a.productId === p.id) : false,
					accountType: p.accountType,
				}) as ProductSelection,
		);
		return products;
	});

	constructor() {}

	onProductCardClicked(productId: string, event?: Event): void {
		if (event) {
			event.preventDefault();
		}
		const selected = this.$products().some((p) => p.id === productId && p.selected === true);
		this.setProductSelected(productId, !selected);
	}

	setProductSelected(productId: string, selected: boolean): void {
		this.$products()
			.filter((p) => p.id === productId)
			.forEach((p) => (p.selected = selected));

		const selectedProducts = this.$products()
			.filter((p) => p.selected)
			.map((p) => ({ productId: p.id, accountType: p.accountType }) as ApplicationAccount);

		this.applicationStateService.updateApplicationValues({ accounts: [...selectedProducts] });
	}

	getProductIcon(productName: string): string {
		switch (productName) {
			case 'The nbkc Everything Account':
				return 'fa-ring';
			case 'Personal Money Market Account':
				return 'fa-chart-line';
			case 'Business Account':
				return 'fa-money-check-alt';
			case 'Business Money Market Account':
				return 'fa-piggy-bank';
			case '7-Month Certificate of Deposit':
			case '11-Month Certificate of Deposit':
			case '1-Year Certificate of Deposit':
			case '2-Year Certificate of Deposit':
			case '3-Year Certificate of Deposit':
			case '4-Year Certificate of Deposit':
			case '5-Year Certificate of Deposit':
				return 'fa-coin';
		}
	}
}
