import { Component, input, OnDestroy, OnInit, signal } from '@angular/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { TaxIdentificationFormGroup } from '@experience/app/components-new/individual-applicant/individual-applicant-form/individual-applicant-form.group';
import { FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormControlPipe } from '@experience/app/shared/pipes/form-control-pipe/form-control.pipe';
import { MaskedInputComponent } from '../masked-input/masked-input.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-tax-identification',
	standalone: true,
	imports: [MaskedInputComponent, MatError, ReactiveFormsModule, MatFormField, FormControlPipe, MatLabel],
	templateUrl: './tax-identification.component.html',
	styleUrl: './tax-identification.component.scss',
})
export class TaxIdentificationComponent implements OnInit, OnDestroy {
	$applicantNameOrDefault = input<string>('');
	$taxIdentificationFormGroup = input<FormGroup<TaxIdentificationFormGroup>>({} as FormGroup<TaxIdentificationFormGroup>);
	$isSSN = input<boolean>(true);
	$taxIdIsMasked = signal(true);
	$showUnmaskToggle = signal(true);

	private subscription = new Subscription();

	ngOnInit() {
		this.$taxIdentificationFormGroup().setValidators(this.taxIdNumberValidator);
		this.$taxIdentificationFormGroup().updateValueAndValidity();

		if (this.$taxIdentificationFormGroup().valid) {
			this.$taxIdIsMasked.set(true);
			this.$showUnmaskToggle.set(false);
			this.$taxIdentificationFormGroup().get('numberConfirmation').disable();
		}

		this.subscription.add(
			this.$taxIdentificationFormGroup().valueChanges.subscribe(() => {
				const confirmationControl = this.$taxIdentificationFormGroup().get('numberConfirmation');
				if (this.$taxIdentificationFormGroup().valid && confirmationControl.valid) {
					this.$taxIdIsMasked.set(true);
					this.$showUnmaskToggle.set(false);
					confirmationControl.disable({ emitEvent: false });
				} else {
					this.$showUnmaskToggle.set(true);
					confirmationControl.enable({ emitEvent: false });
				}
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public onSocialFocused(event: FocusEvent) {
		const socialControl = this.$taxIdentificationFormGroup().get('number');
		const confirmationControl = this.$taxIdentificationFormGroup().get('numberConfirmation');

		if (socialControl?.value?.includes('*')) {
			socialControl.reset();
			confirmationControl.reset();
		} else {
			this.$showUnmaskToggle.set(true);
		}
	}

	private taxIdNumberValidator: ValidatorFn = (taxIdentification: FormGroup): ValidationErrors | null => {
		if (
			taxIdentification.get('number').value !== taxIdentification.get('numberConfirmation').value &&
			taxIdentification.get('numberConfirmation').value !== ''
		) {
			return { taxIdNumberMismatch: true };
		} else {
			return null;
		}
	};
}
