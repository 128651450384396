import { Component, computed, inject, OnInit } from '@angular/core';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { AppDividerComponent } from '../divider/app-divider.component';
import { Product } from '@experience/app/models/tenant/product.model';
import { CommonModule } from '@angular/common';
import { AppCardComponent } from '../card/app-card.component';
import { MatButtonModule } from '@angular/material/button';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { LoadingService } from '@experience/app/services/loading.service';
import { submitApplicationActivity } from '@common/lib/constants/activities';
import { AppRoutingService } from '@experience/app/services/app-routing.service';
import { QualificationFailure } from '@experience/app/models/onboarding/qualification-failure.model';
import { KnownRoutes } from '@experience/app/constants/known-routes';
import { Tracker } from '@nbkc/tracker-angular';
import { NavigationError } from '@experience/app/tracking/events/navigation.error';
import { KnownEvaluationTags } from '@experience/app/constants/known-evaluation-tags';

@Component({
	selector: 'app-review-disclosures',
	standalone: true,
	imports: [CommonModule, MatButtonModule, AppDividerComponent, AppCardComponent, AppTodoHeaderComponent],
	templateUrl: './app-review-disclosures.component.html',
	styleUrl: './app-review-disclosures.component.scss',
})
export class AppReviewDisclosuresComponent implements OnInit {
	private applicationsApiService = inject(ApplicationsApiService);
	private applicationStateService = inject(ApplicationStateService);
	private internalStateService = inject(InternalStateService);
	private loadingService = inject(LoadingService);
	private routingService = inject(AppRoutingService);
	private tracker = inject(Tracker);

	$failuresToDisplay = computed(() => this.applicationStateService.$qualification()?.failures?.splice(0, 10) ?? []);

	$selectedProducts = computed<Product[]>(() => {
		const products = this.internalStateService.$products();
		const accounts = this.applicationStateService.$application().accounts;

		if (!accounts || accounts.length < 0) {
			return [];
		}

		const selectedProducts = [];
		for (const account of accounts) {
			const selectedProduct = products.find((p) => p.id === account.productId);
			if (selectedProduct) {
				selectedProducts.push(selectedProduct);
			}
		}

		return selectedProducts;
	});

	ngOnInit() {
		this.internalStateService.setReviewMode(true);
	}

	submit(): void {
		this.loadingService.startLoadingActivity(submitApplicationActivity);
		this.applicationsApiService.submitApplication().subscribe((res) => {
			this.internalStateService.setReviewMode(false);
			this.routingService.routeByApplicationStatus(res.application.status);
		});
	}

	openDisclosureLink(uri: string, event: Event): void {
		event.preventDefault();
		window.open(uri, '_blank', 'noopener noreferrer');
	}

	navigateToRelatedSection(failure: QualificationFailure): void {
		let route = this.mapFailureToKnownRoute(failure);

		if (!route) {
			this.tracker.event(new NavigationError(route));
			return;
		} else if (route === KnownRoutes.ApplicantDetails) {
			const applicantIndex = this.applicationStateService.$application().applicants?.findIndex((a) => a.id === failure.entityId) ?? -1;
			route += `/${applicantIndex}`;

			if (applicantIndex === -1) {
				this.tracker.event(new NavigationError(route));
				return;
			}
		}

		this.routingService.routeToApplicationScreen(route);
	}

	getProductIcon(productName: string): string {
		switch (productName) {
			case 'The nbkc Everything Account':
				return 'fa-ring';
			case 'Personal Money Market Account':
				return 'fa-chart-line';
			case 'Business Account':
				return 'fa-money-check-alt';
			case 'Business Money Market Account':
				return 'fa-piggy-bank';
			case '7-Month Certificate of Deposit':
			case '11-Month Certificate of Deposit':
			case '1-Year Certificate of Deposit':
			case '2-Year Certificate of Deposit':
			case '3-Year Certificate of Deposit':
			case '4-Year Certificate of Deposit':
			case '5-Year Certificate of Deposit':
				return 'fa-coin';
		}
	}

	mapFailureToKnownRoute(failure: QualificationFailure) {
		if ((failure?.tags?.length ?? 0) <= 0) {
			if (failure.failureMessage.includes('unique social security number')) {
				return KnownRoutes.AddApplicants;
			}
			if (failure.failureMessage.includes('Application must have a Primary Individual Applicant')) {
				return KnownRoutes.PrimaryApplicant;
			}
			return undefined;
		}

		const routeTag = failure.tags[0];
		switch (routeTag) {
			case KnownEvaluationTags.PrimaryApplicant:
				return KnownRoutes.PrimaryApplicant;
			case KnownEvaluationTags.BusinessApplicant:
				return KnownRoutes.BusinessApplicant;
			case KnownEvaluationTags.AdditionalApplicant:
				return KnownRoutes.ApplicantDetails;
			case KnownEvaluationTags.BusinessExpectations:
				return KnownRoutes.BusinessExpectations;
			case KnownEvaluationTags.DebitCard:
				return KnownRoutes.DebitCard;
			case KnownEvaluationTags.Products:
				return KnownRoutes.ProductSelection;
			default:
				return undefined;
		}
	}
}
