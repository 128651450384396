@if (dateControl()) {
	<mat-form-field class="w-full">
		<mat-label>{{ label() }}</mat-label>
		<input
			#dateInput
			matNativeControl
			type="text"
			inputmode="numeric"
			class="date-input"
			max="9999-12-31"
			[formControl]="dateControl()"
			[required]="required()"
			[type]="$inputType()"
			(focus)="focusInput($event)"
			(focusout)="!dateControl().value && $inputType.set('text')"
			(keydown.space)="handleSpaceBarPress($event)"
			data-cy="input-field" />
		@if (!dateControl().valid) {
			<mat-error><ng-content></ng-content></mat-error>
		}
	</mat-form-field>
}
