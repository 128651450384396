<div class="mt-20">
	<div class="availability-content">
		<div class="text-center text-3xl sm:font-extrabold md:text-4xl md:leading-7 md:font-bold md:mb-2 lg:text-left lg:text-5xl">Welcome to nbkc bank!</div>
		<div class="text-base text-gray-600 text-center lg:text-left">Let’s get your new account opened!</div>

		@if (pendingApplications()?.length > 0) {
			<app-divider
				classes="mt-8 mb-8"
				text="In Progress Applications"></app-divider>
			<div [@listAnimation]="pendingApplications().length">
				@for (application of pendingApplications(); track application.id) {
					<div class="card-div-container flex justify-center mb-4">
						<app-card
							[mainTitle]="application.kind === 'Personal' ? 'Personal Account' : 'Business Account'"
							[subtitle]="'Started on ' + (application.startDate | date: 'mediumDate')"
							[secondSubtitle]="ONBOARDING_SUMMARY_CARDS[application.status].statusDescription"
							[bodyText]="ONBOARDING_SUMMARY_CARDS[application.status].description"
							textBodyColor="black"
							[headerIcon]="
								ONBOARDING_SUMMARY_CARDS[application.status][application.kind]?.icon || ONBOARDING_SUMMARY_CARDS[application.status].icon
							"
							[headerIconColor]="ONBOARDING_SUMMARY_CARDS[application.status].headerIconColor"
							[selectable]="application.status !== 'OnHold'"
							[class.cursor-pointer]="$isApplicationCardClickable().get(application.id)"
							(keydown.enter)="handleExistingApplication(application)"
							(keydown.space)="handleExistingApplication(application)"
							(click)="handleExistingApplication(application)"
							[cardActionInfo]="application.status === 'Onboarded' ? 'Go to nbkc.com' : application.status !== 'OnHold' && 'Continue'"
							data-cy="selectable-card"
							class="w-full">
						</app-card>
					</div>
				}
			</div>
		}
		<app-divider
			[classes]="'mt-8 mb-8'"
			[text]="'Start a New Application'"></app-divider>
		<div [@listAnimation]="newApplications.length">
			@for (kind of newApplications; track kind) {
				<div class="card-div-container flex justify-center mb-4">
					<app-card
						[mainTitle]="NEW_APPLICATION_SUMMARY_CARDS[kind].title"
						[headerIcon]="NEW_APPLICATION_SUMMARY_CARDS[kind].icon"
						[headerIconColor]="NEW_APPLICATION_SUMMARY_CARDS[kind].headerIconColor"
						[bodyText]="NEW_APPLICATION_SUMMARY_CARDS[kind].description"
						textBodyColor="black"
						(click)="startNewApplication(kind)"
						(keydown.enter)="startNewApplication(kind)"
						(keydown.space)="startNewApplication(kind)"
						[selectable]="true"
						cardActionInfo="Start"
						data-cy="selectable-card"
						class="w-full cursor-pointer"></app-card>
				</div>
			}
		</div>

		@if (closedApplications()?.length > 0) {
			<app-divider
				[classes]="'mt-8 mb-8'"
				[text]="'Closed Applications'"></app-divider>
			<div [@listAnimation]="closedApplications().length">
				@for (application of closedApplications(); track application.id) {
					<div class="card-div-container flex justify-center mb-4">
						@if (application.status === KnownApplicationStatus.Archived) {
							<app-card
								[mainTitle]="application.kind === 'Personal' ? 'Personal Account' : 'Business Account'"
								subtitle="Started on {{ application.startDate | date: 'mediumDate' }}"
								[secondSubtitle]="ONBOARDING_SUMMARY_CARDS[application.status].statusDescription"
								[bodyText]="
									ONBOARDING_SUMMARY_CARDS[application.status].description + ' ' + (application.modifiedDate | date: 'mediumDate') + '.'
								"
								textBodyColor="black"
								[headerIcon]="ONBOARDING_SUMMARY_CARDS[application.status].icon"
								[headerIconColor]="ONBOARDING_SUMMARY_CARDS[application.status].headerIconColor"
								class="w-full">
							</app-card>
						} @else {
							<app-card
								[mainTitle]="application.kind === 'Personal' ? 'Personal Account' : 'Business Account'"
								subtitle="Started on {{ application.startDate | date: 'mediumDate' }}"
								[secondSubtitle]="ONBOARDING_SUMMARY_CARDS[application.status].statusDescription"
								[bodyText]="ONBOARDING_SUMMARY_CARDS[application.status].description"
								textBodyColor="black"
								[headerIcon]="ONBOARDING_SUMMARY_CARDS[application.status].icon"
								[headerIconColor]="ONBOARDING_SUMMARY_CARDS[application.status].headerIconColor"
								class="w-full">
							</app-card>
						}
					</div>
				}
			</div>
		}
	</div>

	<div class="fixed -z-10 top-2/4 right-0 transform -translate-y-1/2 invisiblemd:visible">
		<img
			alt="side-photo"
			ngSrc="/assets/availability-logo.svg"
			width="1000"
			height="752"
			priority />
	</div>
</div>
