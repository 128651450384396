<app-todo-header></app-todo-header>
@for (person of $application().applicants; track person.id; let i = $index) {
	<div
		class="flex justify-center mb-4"
		[class.hidden]="person.kind === ApplicantKind.Primary">
		<app-card
			[mainTitle]="person.firstName + ' ' + person.lastName"
			[subtitle]="person.email"
			headerIcon="fa-user"
			[cardActions]="['Remove', 'Edit Profile']"
			(actionClicked)="onCardActionClicked($event, i)"
			class="w-full cursor-pointer">
		</app-card>
	</div>
}

<div class="flex justify-center mb-4">
	<app-card
		mainTitle="Add someone else to the account"
		[selectable]="true"
		headerIcon="fa-plus-circle"
		[showContent]="false"
		(click)="onAddSomeoneClicked()"
		(keydown.enter)="onAddSomeoneClicked()"
		(keydown.space)="onAddSomeoneClicked()"
		class="w-full cursor-pointer">
	</app-card>
</div>

<app-next-todo-button></app-next-todo-button>
