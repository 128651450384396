<h2
	mat-dialog-title
	class="flex mb-4">
	<div class="text-6xl mr-4">
		<i class="fa-light fa-block-question"></i>
	</div>
	<div class="text-xl">Are you sure you want to start a new application?</div>
</h2>

<mat-dialog-content class="pb-3 overflow-y-hidden">
	<div class="overflow-auto inline-block">
		<p class="text-lg">
			You created an application on {{ data.lastStartDate | date: 'mediumDate' }}.
			<a
				class="nbkc-blue cursor-pointer"
				tabindex="0"
				(click)="dialogRef.close('continue')"
				(keydown.enter)="dialogRef.close('continue')"
				>Continue Application</a
			>
		</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button
		mat-button
		color="warn"
		[mat-dialog-close]="'cancel'"
		mat-dialog-close>
		Cancel
	</button>
	<button
		mat-button
		color="primary"
		[mat-dialog-close]="'startNew'">
		Start a new application
	</button>
</mat-dialog-actions>
