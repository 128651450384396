import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { BusinessExpectedActivitySurvey } from '../../models/onboarding/business-expected-activity-survey.model';
import { NextTodoButtonComponent } from '../next-button/next-todo-button.component';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { AppCardComponent } from '../card/app-card.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusinessExpectationsFormModel } from './business-expectations-form.model';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { BusinessExpectationsFinanceQuestions, BusinessExpectationsTransactionRanges } from './business-expectations-form-lookups';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';

@Component({
	selector: 'app-business-expectations',
	standalone: true,
	imports: [
		NextTodoButtonComponent,
		AppTodoHeaderComponent,
		AppCardComponent,
		MatSlideToggle,
		MatLabel,
		MatSelect,
		MatOption,
		MatFormField,
		FormsModule,
		ReactiveFormsModule,
		MatError,
		MatHint,
	],
	templateUrl: './business-expectations.component.html',
	styleUrl: './business-expectations.component.scss',
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
		},
	],
})
export class BusinessExpectationsComponent implements OnInit, AfterViewInit {
	private applicationStateService = inject(ApplicationStateService);
	private internalStateService = inject(InternalStateService);
	private apiService = inject(ApplicationsApiService);
	private fb = inject(FormBuilder);
	private $application = this.applicationStateService.$application;

	public businessExpectationsForm: FormGroup<BusinessExpectationsFormModel>;
	public financeQuestions = BusinessExpectationsFinanceQuestions;
	public transactionRanges = BusinessExpectationsTransactionRanges;

	ngAfterViewInit() {
		this.subscribeToFormChanges();
	}

	subscribeToFormChanges(): void {
		this.businessExpectationsForm.valueChanges.subscribe(() => {
			this.updateExpectedActivity();
		});
	}

	updateExpectedActivity() {
		const business = this.$application().business;
		business.expectedActivitySurvey = this.businessExpectationsForm.getRawValue();
		this.$application().wantsToUseAutoBooks = this.businessExpectationsForm.controls.wantsToUseAutoBooks.value;
		this.applicationStateService.updateApplicationValues({ business });
		this.apiService.saveApplication(this.$application()).subscribe();
	}

	ngOnInit(): void {
		const activity = this.$application().business?.expectedActivitySurvey ?? ({} as BusinessExpectedActivitySurvey);

		this.businessExpectationsForm = this.fb.group<BusinessExpectationsFormModel>(
			{
				cashServices: new FormControl(activity.cashServices || false, { updateOn: 'change' }),
				cashDeposits: new FormControl(activity.cashDeposits),
				cashWithdrawals: new FormControl(activity.cashWithdrawals),
				checks: new FormControl(activity.checks || false, { updateOn: 'change' }),
				incomingChecks: new FormControl(activity.incomingChecks),
				outgoingChecks: new FormControl(activity.outgoingChecks),
				automatedClearingHouse: new FormControl(activity.automatedClearingHouse || false, {
					updateOn: 'change',
				}),
				incomingAutomatedClearingHouse: new FormControl(activity.incomingAutomatedClearingHouse),
				outgoingAutomatedClearingHouse: new FormControl(activity.outgoingAutomatedClearingHouse),
				domesticWires: new FormControl(activity.domesticWires || false, { updateOn: 'change' }),
				incomingDomesticWires: new FormControl(activity.incomingDomesticWires),
				outgoingDomesticWires: new FormControl(activity.outgoingDomesticWires),
				internationalWires: new FormControl(activity.internationalWires || false, { updateOn: 'change' }),
				incomingInternationalWires: new FormControl(activity.incomingInternationalWires),
				outgoingInternationalWires: new FormControl(activity.outgoingInternationalWires),
				wantsToUseAutoBooks: new FormControl(this.$application().wantsToUseAutoBooks, {
					updateOn: 'change',
				}),
			},
			{ updateOn: 'blur' },
		);

		if (this.internalStateService.$reviewModeEnabled()) {
			this.businessExpectationsForm.markAllAsTouched();
			this.businessExpectationsForm.updateValueAndValidity();
		}
	}
}
