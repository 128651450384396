import { CommonModule } from '@angular/common';
import { Component, computed, input, model, OnInit, output, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TEXT_MASK_CONFIGS } from '@experience/app/models-ui/text-masks-ngx';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatIconButton } from '@angular/material/button';

@Component({
	selector: 'app-masked-input',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgxMaskDirective, CommonModule, MatSuffix, MatIcon, MatIconButton],
	templateUrl: './masked-input.component.html',
	styleUrl: './masked-input.component.scss',
	providers: [
		provideNgxMask(),
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				subscriptSizing: 'dynamic',
			},
		},
	],
})
export class MaskedInputComponent implements OnInit {
	public control = input<FormControl>();
	public maskType = input<string>();
	public label = input<string>();
	public required = input<boolean>(true);
	public disablePaste = input<boolean>(false);
	public showProtectedToggle = input<boolean>(false);
	public hint = input<string>(undefined);
	public requiredErrorMessage = input<string>('Please enter a value.');
	public maskErrorMessage = input<string>('Please enter a valid value.');
	public errorMessagesEnabled = input<boolean>(true); // If false, consuming component can have full control of displaying errors in the ngContent.

	public $maskConfiguration = signal(undefined);
	public $inputType = signal('text');
	public $inputMode = signal('text');
	public $hideProtected = model(true);
	public $onBlur = output<FocusEvent>();
	public $onFocus = output<FocusEvent>();
	public $mask = computed(() => this.$maskConfiguration()?.mask);

	ngOnInit(): void {
		const maskConfiguration = TEXT_MASK_CONFIGS[this.maskType()];
		if (!maskConfiguration) {
			console.error(`No mask configuration found for ${this.maskType()}. See mask options in TEXT_MASK_CONFIGS`);
			return;
		}
		this.$maskConfiguration.set(maskConfiguration);
		this.$inputType.set(this.getInputType(this.maskType()));
		this.setInputMode();
	}

	toggleShowProtected(): void {
		this.$hideProtected.set(!this.$hideProtected());

		// TODO - If we have other protected mask types in the future, this will have to be modified to support more than SSN
		if (this.$hideProtected()) {
			this.$maskConfiguration.set({ ...this.$maskConfiguration(), mask: 'XXX-XX-0000' });
		} else {
			this.$maskConfiguration.set({ ...this.$maskConfiguration(), mask: '000-00-0000' });
		}

		// The underlying value of the control does not change, but manually setting it forces ngx mask to apply the new mask to the value displayed the UI.
		const currentValue = this.control().value;
		this.control().setValue(currentValue);
	}

	onPaste(event: ClipboardEvent): void {
		if (this.disablePaste()) {
			event.preventDefault();
			return;
		}
	}

	private getInputType(maskType: string): string {
		switch (maskType) {
			case 'age':
				return 'number';
			case 'phone':
				return 'tel';
			default:
				return 'text';
		}
	}

	setInputMode(): void {
		switch (this.maskType()) {
			case 'date':
			case 'ssn':
			case 'ein':
			case 'zip':
				this.$inputMode.set('numeric');
				break;
			case 'phone':
				this.$inputMode.set('tel');
				break;
			default:
				this.$inputMode.set('text');
		}
	}
}
