<div
	[formGroup]="$taxIdentificationFormGroup()"
	class="flex flex-col gap-4">
	@if ($isSSN()) {
		<app-masked-input
			maskType="ssn"
			label="Social Security Number"
			requiredErrorMessage="Please include a valid social security number for {{ $applicantNameOrDefault() }}."
			maskErrorMessage="Please include a valid social security number for {{ $applicantNameOrDefault() }}."
			[control]="$taxIdentificationFormGroup().get('number') | formControl"
			[$hideProtected]="$taxIdIsMasked()"
			[showProtectedToggle]="$showUnmaskToggle()"
			($onFocus)="onSocialFocused($event)">
			@if ($taxIdentificationFormGroup().hasError('taxIdNumberMismatch') && $taxIdentificationFormGroup().get('number').valid) {
				<mat-error>Please confirm that social security numbers for {{ $applicantNameOrDefault() }} match.</mat-error>
			}
		</app-masked-input>
		<app-masked-input
			maskType="ssn"
			label="Confirm Social Security Number"
			requiredErrorMessage="Please confirm social security number for {{ $applicantNameOrDefault() }}."
			[control]="$taxIdentificationFormGroup().get('numberConfirmation') | formControl"
			[$hideProtected]="$taxIdIsMasked()"
			[showProtectedToggle]="$showUnmaskToggle()"
			[disablePaste]="true">
			@if ($taxIdentificationFormGroup().hasError('taxIdNumberMismatch') && $taxIdentificationFormGroup().get('numberConfirmation').valid) {
				<mat-error>Please confirm that social security numbers for {{ $applicantNameOrDefault() }} match.</mat-error>
			}
		</app-masked-input>
	} @else {
		<app-masked-input
			maskType="ein"
			label="Employer Identification Number (EIN)"
			requiredErrorMessage="Please include a valid EIN for {{ $applicantNameOrDefault() }}."
			maskErrorMessage="Please include a valid EIN for {{ $applicantNameOrDefault() }}."
			[control]="$taxIdentificationFormGroup().get('number') | formControl">
			@if ($taxIdentificationFormGroup().hasError('taxIdNumberMismatch') && $taxIdentificationFormGroup().get('number').valid) {
				<mat-error>Please confirm that EINs for {{ $applicantNameOrDefault() }} match.</mat-error>
			}
		</app-masked-input>
		<app-masked-input
			maskType="ein"
			label="Confirm Employer Identification Number"
			requiredErrorMessage="Please confirm EIN for {{ $applicantNameOrDefault() }}."
			[control]="$taxIdentificationFormGroup().get('numberConfirmation') | formControl"
			[disablePaste]="true">
			@if ($taxIdentificationFormGroup().hasError('taxIdNumberMismatch') && $taxIdentificationFormGroup().get('numberConfirmation').valid) {
				<mat-error>Please confirm that EINs for {{ $applicantNameOrDefault() }} match.</mat-error>
			}
		</app-masked-input>
	}
</div>
