<app-todo-header [$hideBackButton]="true"></app-todo-header>
<div class="mb-4 pl-2 text-base nbkc-dark-gray">
	<a
		target="_blank"
		href="{{ q2LoginFormURL }}"
		rel="noopener noreferrer">
		Log in as an existing user
	</a>
	or create your nbkc user below.
</div>
<app-card
	mainTitle="Create your nbkc user"
	headerIcon="fa-shield-keyhole">
	<div class="w-full">
		<form
			[formGroup]="enrollmentForm"
			class="w-full"
			action="{{ q2LoginFormURL }}"
			method="post"
			#autoLoginForm>
			<mat-form-field
				class="w-full"
				appearance="outline">
				<mat-label>Username</mat-label>
				<input
					matInput
					required
					formControlName="username"
					id="user_id"
					name="user_id"
					(keydown.enter)="checkSubmitOnEnter($event)"
					data-cy="input-field" />
			</mat-form-field>
			<div class="pt-2.5 pb-2.5">
				@if (usernameFormControl?.dirty && !usernameFormControl?.valid) {
					@if (usernameFormControl?.hasError('required')) {
						<div class="pl-2.5 validation-message">Please enter your online banking username</div>
					} @else if (usernameFormControl?.hasError('maxlength')) {
						<div class="pl-2.5 validation-message">Username must be less than 21 characters long</div>
					} @else if (usernameFormControl?.hasError('minlength')) {
						<div class="pl-2.5 validation-message">Username must be at least 6 characters long</div>
					} @else if (usernameFormControl?.hasError('invalidCharacters')) {
						<div class="pl-2.5 validation-message">Username may only contain letters, numbers, and the following special characters: _ and -</div>
					} @else if (usernameFormControl?.hasError('noLetter')) {
						<div class="pl-2.5 validation-message">Username must contain at least 1 letter</div>
					}
				}
			</div>
			<app-password [$formGroup]="enrollmentForm"> </app-password>
			<mat-checkbox formControlName="consent">
				<div>
					I agree to the
					<a
						href="https://www.nbkc.com/legal-notices/disclosures/online-and-mobile-banking-agreement"
						target="_blank"
						rel="noopener noreferrer"
						>Online & Mobile Banking Agreement</a
					>.
				</div>
			</mat-checkbox>
			<button
				id="autoLoginButton"
				#autoLoginButton
				type="submit"
				(click)="autoLoginForm.submit()"></button>
		</form>
	</div>
</app-card>
<div class="flex flex-col justify-center items-center m-4">
	<button
		class="w-full text-white rounded-full py-3 px-4 shadow-button text-lg"
		style="transition: width 0.75s ease-in-out"
		[ngClass]="{ 'w-full': !$enrollmentInProgress(), 'w-0': $enrollmentInProgress() }"
		color="primary"
		mat-raised-button
		(click)="enroll()"
		[disabled]="!enrollmentForm.valid || $enrollmentInProgress()">
		<span *ngIf="!$enrollmentInProgress()">Create User</span>
		<div
			*ngIf="$enrollmentInProgress()"
			class="flex flex-col items-center">
			<mat-spinner
				color="primary"
				[diameter]="30"></mat-spinner>
		</div>
	</button>
	@if ($serverErrorMessage()) {
		<div class="pt-2.5 nbkc-red">{{ $serverErrorMessage() }}</div>
	}
</div>
