<app-todo-header></app-todo-header>
<app-divider text="Legal Disclosures"></app-divider>
@for (product of $selectedProducts(); track product.id) {
	<div class="card-div-container flex justify-center mb-6">
		<app-card
			class="w-full"
			subtitle="Legal Disclosures"
			[mainTitle]="product.name"
			[headerIcon]="getProductIcon(product.name)">
			@for (disclosure of product.disclosures; track $index) {
				<div class="flex justify-start p-0">
					<a
						mat-flat-button
						class="text-left whitespace-normal text-lg p-1 h-auto"
						target="_blank"
						href="{{ disclosure.uri }}"
						(keydown.space)="openDisclosureLink(disclosure.uri, $event)"
						rel="noopener noreferrer"
						[attr.aria-label]="'Open the link for ' + disclosure.name + ' in a new window'">
						{{ disclosure.name }}
					</a>
				</div>
			}
		</app-card>
	</div>
}

@if ($failuresToDisplay()?.length > 0) {
	<app-divider text="Complete before you proceed"></app-divider>
	@for (failure of $failuresToDisplay(); track $index) {
		<div class="card-div-container flex justify-center mb-6">
			<app-card
				[selectable]="true"
				class="w-full cursor-pointer"
				(click)="navigateToRelatedSection(failure)"
				(keydown.enter)="navigateToRelatedSection(failure)"
				(keydown.space)="navigateToRelatedSection(failure)"
				data-cy="selectable-card">
				<div class="flex justify-between">
					<div class="flex">
						<i class="far fa-exclamation-square text-3xl mr-3 self-center"></i>
						<span class="max-w-xs review-message whitespace-pre-wrap self-center">{{ failure.failureMessage }}</span>
					</div>
					<div class="link-text font-medium self-center">Go to Section</div>
				</div>
			</app-card>
		</div>
	}
} @else {
	<div class="card-div-container flex justify-center mb-6">
		<app-card
			class="w-full"
			mainTitle="Everything Looks Good"
			headerIcon="fa-check"
			headerIconColor="blue"
			bodyText="By clicking Submit you confirm that you had the opportunity to review the legal disclosures.">
		</app-card>
	</div>
}

<div class="flex justify-center">
	<button
		class="max-w-xs w-full text-white rounded-full py-3 px-4 shadow-button text-lg"
		mat-raised-button
		color="primary"
		data-cy="submit-button"
		[disabled]="$failuresToDisplay()?.length > 0"
		(click)="submit()">
		Submit
	</button>
</div>
