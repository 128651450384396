<form [formGroup]="businessExpectationsForm">
	<app-todo-header></app-todo-header>
	@for (question of financeQuestions; track question) {
		<div class="mb-4 w-100">
			<app-card>
				<div class="flex-column gap-2 w-100">
					<div
						class="flex gap-4 items-start"
						data-cy="toggle-container">
						<div class="nbkc-not-black">
							{{ question.description }}
						</div>
						<mat-label>No</mat-label>
						<div>
							<mat-slide-toggle
								[formControlName]="question.toggleControlName"
								[aria-label]="question.description"
								data-cy="toggle-field"></mat-slide-toggle>
						</div>
						<mat-label>Yes</mat-label>
					</div>
					@if (question.promptText) {
						<div class="ml-2">
							<mat-hint>{{ question.promptText }}</mat-hint>
						</div>
					}
				</div>
				@if (businessExpectationsForm.get(question.toggleControlName).value) {
					<div class="w-full mt-4">{{ question.inDescription }}</div>
					<mat-form-field class="w-full mt-2">
						<mat-label>
							{{ question.inLabel }}
						</mat-label>
						<mat-select
							[formControlName]="question.inControl"
							[required]="businessExpectationsForm.get(question.toggleControlName).value"
							data-cy="select-field">
							@for (transactionRange of transactionRanges; track transactionRange) {
								<mat-option [value]="transactionRange">{{ transactionRange }}</mat-option>
							}
						</mat-select>
						<mat-error>Please select an amount.</mat-error>
					</mat-form-field>

					<div class="w-full mt-4">{{ question.outDescription }}</div>
					<mat-form-field class="w-full mt-2">
						<mat-label>
							{{ question.outLabel }}
						</mat-label>
						<mat-select
							[formControlName]="question.outControl"
							[required]="businessExpectationsForm.get(question.toggleControlName).value"
							data-cy="select-field">
							@for (transactionRange of transactionRanges; track transactionRange) {
								<mat-option [value]="transactionRange">{{ transactionRange }}</mat-option>
							}
						</mat-select>
						<mat-error>Please select an amount.</mat-error>
					</mat-form-field>
				}
			</app-card>
		</div>
	}
	<app-card>
		<div class="flex flex-col gap-2">
			<div
				class="flex gap-4 items-start"
				data-cy="toggle-container">
				<div
					class="w-full nbkc-not-black"
					id="auto-books-question">
					Are you interested in using Autobooks?
				</div>
				<mat-label>No</mat-label>
				<div>
					<mat-slide-toggle
						formControlName="wantsToUseAutoBooks"
						data-cy="toggle-field"
						aria-labelledby="auto-books-question"></mat-slide-toggle>
				</div>
				<mat-label>Yes</mat-label>
			</div>

			<div>
				<mat-hint>
					At nbkc bank, we partner with Autobooks so you can send invoices to your customers and get paid electronically (via ACH, Debit & Credit
					Cards).
				</mat-hint>
			</div>
			<div>
				<mat-hint>
					By selecting yes, you authorize nbkc to share your information with Autobooks so they can contact you. You can opt-out of sharing at any
					time by contacting nbkc.
				</mat-hint>
			</div>
		</div>
	</app-card>
	<app-next-todo-button></app-next-todo-button>
</form>
