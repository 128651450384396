import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationSummary } from '@experience/app/models/onboarding/application-summary.model';
import { Observable, of } from 'rxjs';
import { CommonConfiguration } from '@common/lib/utilities/configuration/common.configuration';
import { Application } from '../models/onboarding/application.model';
import { ApplyResponse } from '../models/onboarding/apply-response.model';
import { SaveApplication } from '../models/onboarding/actions/save-application.model';
import { StartNewApplication } from '../models/onboarding/actions/start-new-application.model';
import { ApplicationOrigin } from '../models/onboarding/application-origin.model';
import { EnrollInOnlineBanking } from '../models/onboarding/actions/enroll-in-online-banking.model';
import { SubmitApplication } from '../models/onboarding/actions/submit-application.model';
import { catchError, finalize, tap, map } from 'rxjs/operators';
import { LoadingService } from '@experience/app/services/loading.service';
import {
	getApplicationActivity,
	getApplicationSummariesActivity,
	getLookupsActivity,
	getProductsActivity,
	startApplicationActivity,
	submitApplicationActivity,
} from '@common/lib/constants/activities';
import { GeneralError } from '@common/lib/models/general-error.model';
import { CommonErrorService } from '@common/lib/services/common-error.service';
import { Product } from '@experience/app/models/tenant/product.model';
import { LookupGroup } from '@experience/app/models/tenant/lookup-group.model';
import { ProviderError } from '@common/lib/models/provider-error.model';
import { ErrorTypes } from '@common/lib/models/enum/error-types.enum';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { AppSession } from '../app.session';
import { BrowserDataUtility } from '@common/lib/utilities/browser-data-utility';
import { ClientContext } from '../models/client-context';
import { ThirdPartyIntegrationToken } from '../models/onboarding/third-party-integration-token.model';
import { ThirdPartyTokenProvider } from '../models/enums/third-party-token-provider';
import { ApplicationKind } from '../models/enums/application-kind';
import { IdentityVerified } from '../models/onboarding/actions/identity-verified.model';
import { Condition } from '../models/onboarding/condition.model';

@Injectable({
	providedIn: 'root',
})
export class ApplicationsApiService {
	readonly config = inject(CommonConfiguration);
	private http = inject(HttpClient);
	private errorService = inject(CommonErrorService);
	private loadingService = inject(LoadingService);
	private accountApplicationStateService = inject(ApplicationStateService);
	private internalStateService = inject(InternalStateService);
	private sessionUtility = inject(AppSession);
	private browserDataUtility = inject(BrowserDataUtility);

	private baseUrl = `${this.config.experienceApi}/applications`;

	constructor() {}

	getApplicationSummaries$(): Observable<ApplicationSummary[]> {
		this.loadingService.startLoadingActivity(getApplicationSummariesActivity);
		return this.http.get<ApplicationSummary[]>(`${this.baseUrl}`).pipe(
			finalize(() => {
				this.loadingService.stopLoadingActivity(getApplicationSummariesActivity);
			}),
			catchError((err) => {
				this.handleError(err);
				return of([]);
			}),
		);
	}

	getProducts(kind: string): Observable<Product[]> {
		this.loadingService.startLoadingActivity(getProductsActivity);
		return this.http.get<Product[]>(`${this.baseUrl}/products/${kind}`).pipe(
			tap((products) => {
				this.internalStateService.setProducts(products);
			}),
			finalize(() => {
				this.loadingService.stopLoadingActivity(getProductsActivity);
			}),
			catchError((err) => {
				this.handleError(err);
				return of([] as Product[]);
			}),
		);
	}

	getLookups(kind: string): Observable<LookupGroup[]> {
		this.loadingService.startLoadingActivity(getLookupsActivity);
		return this.http.get<LookupGroup[]>(`${this.baseUrl}/lookups/${kind}`).pipe(
			tap((groups) => {
				this.internalStateService.setLookups(groups);
			}),
			finalize(() => {
				this.loadingService.stopLoadingActivity(getLookupsActivity);
			}),
			catchError((err) => {
				this.handleError(err);
				return of([] as LookupGroup[]);
			}),
		);
	}

	getApplication(applicationId: string): Observable<Application> {
		this.loadingService.startLoadingActivity(getApplicationActivity);
		this.sessionUtility.updateSession({ applicationId });
		this.accountApplicationStateService.clearState();

		return this.http.get<ApplyResponse>(`${this.baseUrl}/${applicationId}`).pipe(
			map((response: ApplyResponse) => {
				this.setApplicationState(response);
				return response.application;
			}),
			finalize(() => {
				this.loadingService.stopLoadingActivity(getApplicationActivity);
			}),
			catchError((err) => {
				this.handleError(err);
				return of({} as Application);
			}),
		);
	}

	saveApplication(application: Application): Observable<Application> {
		return this.http
			.post<ApplyResponse>(`${this.baseUrl}/${application.id}`, {
				application,
				applicationId: application.id,
				lastVisitedScreen: this.internalStateService.$currentScreen(),
			} as SaveApplication)
			.pipe(
				map((response) => {
					this.setApplicationState(response);
					return response.application;
				}),
				catchError((error) => {
					this.handleError(error, ErrorTypes.recoverable);
					return of({} as Application);
				}),
			);
	}

	startApplication(kind: string): Observable<Application> {
		if (kind !== ApplicationKind.Personal && kind !== ApplicationKind.Business) {
			throw new RangeError('Kind must be personal or business');
		}

		this.accountApplicationStateService.clearState();

		let clientContext: ClientContext = null;
		this.sessionUtility.data$.subscribe((response) => {
			clientContext = response;
		});

		const startNewApplication: StartNewApplication = {
			kind,
			origin: this.buildApplicationOrigin(clientContext),
			thirdPartyIntegrationTokens: this.hydrateIntegrationTokens(clientContext),
			applicationId: undefined,
		};

		return this.http.post<ApplyResponse>(`${this.baseUrl}/start`, startNewApplication).pipe(
			map((r) => {
				this.setApplicationState(r);
				return r.application;
			}),
			finalize(() => {
				this.loadingService.stopLoadingActivity(startApplicationActivity);
			}),
			catchError((err) => {
				this.handleError(err);
				return of({} as Application);
			}),
		);
	}

	submitApplication(): Observable<ApplyResponse> {
		const application = this.accountApplicationStateService.$application();
		return this.http
			.post<ApplyResponse>(`${this.baseUrl}/${application.id}/submit`, {
				application,
				applicationId: application.id,
			} as SubmitApplication)
			.pipe(
				tap((res: ApplyResponse) => {
					this.setApplicationState(res);
				}),
				finalize(() => this.loadingService.stopLoadingActivity(submitApplicationActivity)),
			);
	}

	enrollApplicant(applicationId: string, username: string, password: string): Observable<ApplyResponse> {
		const enrollInOnlineBanking: EnrollInOnlineBanking = {
			username,
			password,
			applicationId,
		};

		return this.http.post<ApplyResponse>(`${this.baseUrl}/${applicationId}/enroll`, enrollInOnlineBanking);
	}

	identityVerified(applicationId: string, conditionId: string): Observable<ApplyResponse> {
		const idVerified: IdentityVerified = {
			applicationId,
			conditionId,
		};

		return this.http
			.post<ApplyResponse>(`${this.baseUrl}/${applicationId}/identityVerified`, idVerified)
			.pipe(tap((response) => this.setApplicationState(response)));
	}

	uploadDocuments(files: File[], applicationId: string, condition: Condition): Observable<ApplyResponse> {
		const formData = new FormData();
		for (const file of files) {
			formData.append('files', file);
		}
		formData.append('conditionId', condition.id);
		formData.append('conditionName', condition.name);
		formData.append('entityId', condition.applicantId);

		return this.http.post<ApplyResponse>(`${this.baseUrl}/${applicationId}/uploadDocuments`, formData);
	}

	// SharedUtility methods for API calls
	private setApplicationState(response: ApplyResponse): void {
		this.accountApplicationStateService.setApplication(response.application);
		this.accountApplicationStateService.setQualification(response.qualification);
		this.accountApplicationStateService.setId(response.id);
		this.internalStateService.setApplicationKind(response.application.kind);
		this.internalStateService.setIsOldExperience(response.application.isConverted);
		this.internalStateService.setProgress(response.progress);
		this.sessionUtility.updateSession({ applicationId: response.application.id });
	}

	private buildApplicationOrigin(clientContext: ClientContext): ApplicationOrigin {
		const browserData = this.browserDataUtility.getBrowserData();
		const referralSource = clientContext.sessionParams?.['affiliate'];
		const queryParameters: { [key: string]: string } = {};
		Object.keys(clientContext.sessionParams).forEach((key) => {
			queryParameters[key] = clientContext.sessionParams[key]?.toString();
		});

		const applicationOrigin: ApplicationOrigin = {
			deviceInformation: {
				browser: browserData.browser,
				device: browserData.device,
				location: browserData.location,
				operatingSystem: browserData.operatingSystem,
			},
			initialUrl: clientContext?.firstUrl,
			parameters: queryParameters,
			referralSource,
		};
		return applicationOrigin;
	}

	private hydrateIntegrationTokens(clientContext: ClientContext): ThirdPartyIntegrationToken[] {
		return [
			{
				provider: ThirdPartyTokenProvider.Socure,
				value: clientContext.socureSessionid,
			},
			{
				provider: ThirdPartyTokenProvider.Iovation,
				value: clientContext.iovationBlackBoxToken,
			},
		];
	}

	private handleError(err: Error, errorType = ErrorTypes.unableToLoad) {
		const providerError = new ProviderError(errorType, err.message, err);
		this.errorService.handleError(providerError as GeneralError);
	}
}
