import { computed, inject, Injectable } from '@angular/core';
import { TodoItem } from '@common/lib/models/todo-item';
import { ApplicationStateService } from './application-state.service';
import { InternalStateService } from './internal-state.service';
import { personalTodoItems } from '../models-ui/todo-items/personal-todo-items.model';
import { businessTodoItems } from '../models-ui/todo-items/business-todo-items.model';
import { KnownRoutes } from '../constants/known-routes';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';

@Injectable({
	providedIn: 'root',
})
export class TodoListService {
	private applicationStateService = inject(ApplicationStateService);
	private internalStateService = inject(InternalStateService);

	public $todoItems = computed<TodoItem[]>(() => {
		const todoItems = this.getInitialTodoList();
		if (this.applicationStateService.$application().status === knownApplicationStatus.InProgress) {
			this.syncTodoListWithProgress(todoItems);
		} else {
			this.markPreviousScreensAsComplete(todoItems);
		}
		this.setVisibilityOfConditionalScreens(todoItems);
		return todoItems;
	});

	private getInitialTodoList() {
		if (this.internalStateService.$isPersonal()) {
			return [...personalTodoItems()];
		} else {
			return [...businessTodoItems()];
		}
	}

	private syncTodoListWithProgress(todoItems: TodoItem[]) {
		const progress = this.internalStateService.$progress();
		if (progress?.visitedScreens && progress.visitedScreens.length > 0) {
			todoItems.forEach((t) => (t.complete = progress.visitedScreens.includes(t.associatedRoute)));
		} else {
			todoItems.forEach((t) => (t.complete = false));
		}
	}

	private markPreviousScreensAsComplete(todoItems: TodoItem[]) {
		const currentScreen = this.internalStateService.$currentScreen();
		const currentScreenIndex = todoItems.findIndex((t) => t.associatedRoute === currentScreen);
		todoItems.forEach((t, i) => {
			if (i < currentScreenIndex) {
				t.complete = true;
			}
		});
	}

	private setVisibilityOfConditionalScreens(todoItems: TodoItem[]) {
		const debitCardItem = todoItems.find((t) => t.associatedRoute === KnownRoutes.DebitCard);
		debitCardItem.visible = this.debitCardScreenShouldBeVisible();

		const addMorePeopleItem = todoItems.find((t) => t.associatedRoute === KnownRoutes.AddApplicants);
		addMorePeopleItem.visible = this.additionalApplicantsScreenShouldBeVisible();
	}

	private debitCardScreenShouldBeVisible(): boolean {
		const application = this.applicationStateService.$application();
		const selectedProductIds = application.accounts?.map((a) => a.productId) ?? [];

		if (!selectedProductIds?.length || selectedProductIds.length <= 0) {
			return false;
		}

		const selectedProducts =
			this.internalStateService.$products()?.filter((p) => selectedProductIds.includes(p.id)) ?? [];
		if (
			!selectedProducts?.length ||
			selectedProducts.length === 0 ||
			!selectedProducts.some((p) => p.offersDebitCard)
		) {
			return false;
		}

		return true;
	}

	private additionalApplicantsScreenShouldBeVisible(): boolean {
		const application = this.applicationStateService.$application();

		if (
			!this.internalStateService.$isPersonal() &&
			application.business?.structure?.toLowerCase() === 'sole proprietor'
		) {
			return false;
		}

		return true;
	}
}
