import { animate, group, query, sequence, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = trigger('routerAnimations', [
	transition('* => forward', [
		query(
			':enter, :leave',
			[
				style({
					display: 'block',
					position: 'relative',
				}),
			],
			{ optional: true },
		),
		query(':enter', [style({ opacity: 0, transform: 'translateY(25%)' })], { optional: true }),
		query(
			':leave',
			[
				style({ transform: 'translateY(0)', opacity: 1 }),
				sequence([
					group([
						animate(`300ms ease-in-out`, style({ transform: 'translateY(-25%)' })),
						animate('300ms ease-in-out', style({ opacity: 0 })),
					]),
					style({ height: 0 }),
				]),
			],
			{ optional: true },
		),
		query(
			':enter',
			[
				group([
					animate(`400ms 200ms ease-in-out`, style({ transform: 'translateY(0)' })),
					animate('400ms 200ms ease-in-out', style({ opacity: 1 })),
				]),
			],
			{ optional: true },
		),
	]),
	transition('* => back', [
		query(
			':enter, :leave',
			[
				style({
					display: 'block',
					position: 'relative',
				}),
			],
			{ optional: true },
		),
		query(':enter', [style({ opacity: 0, transform: 'translateY(-25%)', display: 'none' })], { optional: true }),
		query(
			':leave',
			[
				style({ transform: 'translateY(0)', opacity: 1 }),
				sequence([
					group([
						animate(`300ms ease-in-out`, style({ transform: 'translateY(25%)' })),
						animate('300ms ease-in-out', style({ opacity: 0 })),
					]),
					style({ height: 0 }),
				]),
			],
			{ optional: true },
		),
		query(
			':enter',
			[
				sequence([
					style({ display: 'block' }),
					group([
						animate(`400ms 200ms ease-in-out`, style({ transform: 'translateY(0)' })),
						animate('400ms 200ms ease-in-out', style({ opacity: 1 })),
					]),
				]),
			],
			{ optional: true },
		),
	]),
]);
