@if (uploadList()?.length > 0) {
	<div class="w-full flex justify-center pb-1">
		<hr class="mt-2 flex-grow" />
		<span class="recent-files-label text-xs pl-1 pr-1">Documents Uploaded</span>
		<hr class="mt-2 flex-grow" />
	</div>
	@for (upload of uploadList(); track $index) {
		<div class="document-list-item">
			<i
				class="text-sm pr-1"
				[ngClass]="upload.displayUpload ? 'far fa-file-alt' : 'far fa-check-circle'"></i>
			{{ upload.fileName }}
			@if (upload.displayUpload) {
				<app-upload-progress
					*ngIf="upload.displayUpload"
					[uploadGroupId]="upload.documentUploadGroupId"
					[uploadComplete]="upload.uploadComplete">
				</app-upload-progress>
			}
		</div>
	}
}
