import { Component, computed, inject, OnInit, signal, Signal, model } from '@angular/core';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { NextTodoButtonComponent } from '../next-button/next-todo-button.component';
import { AppCardComponent } from '../card/app-card.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { IndividualApplicant } from '@experience/app/models/onboarding/individual-applicant.model';
import { MatCheckbox } from '@angular/material/checkbox';
import { ApplicantDebitCardForm } from './applicant-debit-card-form.model';
import { ApplicationDebitCard } from '@experience/app/models/onboarding/application-debit-card.model';
import { MatInput } from '@angular/material/input';
import { InternalStateService } from '@experience/app/services/internal-state.service';

@Component({
	selector: 'app-debit-card',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AppTodoHeaderComponent,
		NextTodoButtonComponent,
		AppCardComponent,
		MatLabel,
		MatSlideToggle,
		MatCheckbox,
		MatInput,
		MatFormField,
		MatError,
		MatHint,
	],
	templateUrl: './app-debit-card.component.html',
	styleUrl: './app-debit-card.component.scss',
	providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', subscriptSizing: 'dynamic' } }],
})
export class AppDebitCardComponent implements OnInit {
	private applicationStateService = inject(ApplicationStateService);
	private internalStateService = inject(InternalStateService);

	public readonly maxCardLength = 20;
	public businessNameOnCardControl: FormControl;
	public $isReviewModeEnabled = this.internalStateService.$reviewModeEnabled;

	public $wantsDebitCard = model<boolean>(false);
	public $charactersRemainingMessage = signal<string>(undefined);
	public $isBusinessApplication: Signal<boolean> = computed(() => !this.internalStateService.$isPersonal());

	public $debitCardSelections: Signal<ApplicationDebitCard[]> = computed(() => this.applicationStateService.$application().debitCards);

	public $applicants: Signal<ApplicantDebitCardForm[]> = computed(() => {
		const applicationApplicants: IndividualApplicant[] = this.applicationStateService.$application()?.applicants;
		if ((applicationApplicants?.length ?? 0) <= 0) {
			return [];
		}

		const debitCardApplicants: ApplicantDebitCardForm[] = [];
		for (const applicant of applicationApplicants) {
			if (!applicant.firstName || !applicant.lastName) {
				continue;
			}

			debitCardApplicants.push({
				id: applicant.id,
				firstName: applicant.firstName,
				lastName: applicant.lastName,
				isSelected: this.$debitCardSelections()?.some((d) => d.applicantId === applicant.id),
			});
		}

		return debitCardApplicants;
	});

	ngOnInit(): void {
		this.$wantsDebitCard.set(this.applicationStateService.$application().debitCards?.length > 0);
		if (this.$isBusinessApplication()) {
			const businessNameOnCard = this.getInitialBusinessNameOnCard();
			this.setCharactersRemainingMessage(businessNameOnCard);

			this.businessNameOnCardControl = new FormControl(businessNameOnCard?.toUpperCase(), [
				Validators.required,
				Validators.maxLength(this.maxCardLength),
			]);

			this.businessNameOnCardControl.valueChanges.subscribe((newValue) => {
				this.businessNameOnCardControl.setValue(newValue?.toUpperCase(), { emitEvent: false });
				this.setCharactersRemainingMessage(newValue);
			});

			if (this.$isReviewModeEnabled()) {
				this.businessNameOnCardControl.markAsTouched();
				this.businessNameOnCardControl.markAsDirty();
				this.businessNameOnCardControl.updateValueAndValidity();
			}
		}
	}

	public onWantsDebitCardChanged(toggleChange: MatSlideToggleChange) {
		if (toggleChange.checked && (this.$debitCardSelections()?.length ?? 0) <= 0) {
			// Select all applicants when user toggles they want a debit card for the first time.
			this.selectAllApplicants();
		} else if (!toggleChange.checked) {
			this.applicationStateService.updateApplicationValues({ debitCards: [] });
		}
	}

	public onBusinessNameOnCardBlur(): void {
		const newBusinessName = this.businessNameOnCardControl.value;
		const currentSelections = this.$debitCardSelections();

		if ((currentSelections?.length ?? 0) <= 0) {
			return;
		}

		currentSelections.forEach((debitCard) => (debitCard.businessNameOnCard = newBusinessName));
		this.applicationStateService.updateApplicationValues({ debitCards: currentSelections });
	}

	public onApplicantChecked(applicant: ApplicantDebitCardForm) {
		applicant.isSelected = !applicant.isSelected;
		let currentSelections: ApplicationDebitCard[] = this.$debitCardSelections();

		if (applicant.isSelected) {
			currentSelections.push({
				applicantId: applicant.id,
				nameOnCard: `${applicant.firstName} ${applicant.lastName}`,
				businessNameOnCard: this.businessNameOnCardControl?.value,
			});
			this.applicationStateService.updateApplicationValues({ debitCards: currentSelections });
		} else {
			currentSelections = currentSelections.filter((d) => d.applicantId !== applicant.id);
			this.applicationStateService.updateApplicationValues({ debitCards: currentSelections });
		}
	}

	private selectAllApplicants(): void {
		const debitCardsForAll: ApplicationDebitCard[] = [];
		for (const applicant of this.$applicants()) {
			debitCardsForAll.push({
				applicantId: applicant.id,
				nameOnCard: `${applicant.firstName} ${applicant.lastName}`,
				businessNameOnCard: this.businessNameOnCardControl?.value,
			});
		}
		this.applicationStateService.updateApplicationValues({ debitCards: debitCardsForAll });
	}

	private getInitialBusinessNameOnCard(): string {
		// If user already setup a card, use the business name they entered.
		let businessNameOnCard = this.$debitCardSelections()?.find((d) => d.businessNameOnCard)?.businessNameOnCard;

		// If a card has not been setup yet, default to the business name.
		if (!businessNameOnCard) {
			businessNameOnCard = this.applicationStateService.$application()?.business?.name;
		}

		return businessNameOnCard?.slice(0, this.maxCardLength);
	}

	private setCharactersRemainingMessage(newCardNameValue) {
		const charactersRemaining = this.maxCardLength - (newCardNameValue?.length ?? 0);
		if (charactersRemaining < this.maxCardLength) {
			this.$charactersRemainingMessage.set(`${charactersRemaining} characters remaining`);
		} else {
			this.$charactersRemainingMessage.set(undefined);
		}
	}
}
