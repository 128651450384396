import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, input, OnInit, signal, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { formatDate } from '@angular/common';

@Component({
	selector: 'app-date-input',
	standalone: true,
	imports: [ReactiveFormsModule, MatFormField, MatInput, MatError, MatLabel],
	templateUrl: './date-input.component.html',
	styleUrl: './date-input.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				subscriptSizing: 'dynamic',
			},
		},
	],
})
export class DateInputComponent implements OnInit {
	@ViewChild('dateInput') dateInput: ElementRef<HTMLInputElement>;

	changeDetectorRef = inject(ChangeDetectorRef);

	dateControl = input.required<FormControl>();
	label = input<string>('');
	required = input<boolean>(false);

	// changes input type to date on focus or if the value is a valid date
	$inputType = signal<string>('text');

	ngOnInit() {
		const control = this.dateControl();
		if (control?.value) {
			this.$inputType.set('date');
			try {
				const formattedDate = this.formatDateForNativeControl(control.value);
				if (formattedDate) {
					control.setValue(formattedDate, { emitEvent: false });
				}
			} catch (error) {
				console.warn('Invalid date value:', control.value);
			}
		}
	}

	formatDateForNativeControl(date: Date | string): string {
		return date ? formatDate(date, 'MM/dd/yyyy', 'en-US', 'UTC') : '';
	}

	handleSpaceBarPress(event: Event) {
		event.preventDefault();
	}

	focusInput(event: Event) {
		event?.stopPropagation();
		this.$inputType.set('date');
		this.changeDetectorRef.detectChanges();
		this.dateInput.nativeElement.focus();
	}
}
