<div [ngClass]="options?.containerClass || 'flex justify-center m-3 mt-24'">
	<div id="wrapper-for-sticky-list">
		<div class="fixed h-full">
			<experience-todo-list
				*ngIf="!options?.hideTodoList || $hiddenTodoList()"
				class="sticky"
			></experience-todo-list>
		</div>
	</div>
	<div class="workflow-screen w-full max-w-xl">
		<div [@routerAnimations]="$animationDirection()" (@routerAnimations.done)="doneAnimating()">
			<router-outlet #outlet="outlet" (activate)="onRouterOutletActivated(outlet)"></router-outlet>
		</div>
	</div>
</div>
