import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { AppRoutingService } from '@experience/app/services/app-routing.service';
import { KnownRoutes } from '@experience/app/constants/known-routes';
import { TodoItem } from '@common/lib/models/todo-item';
import { TodoListService } from '@experience/app/services/todo-list-service';

@Component({
	selector: 'app-next-todo-button',
	standalone: true,
	imports: [MatButtonModule],
	templateUrl: './next-todo-button.component.html',
	styleUrl: './next-todo-button.component.scss',
})
export class NextTodoButtonComponent {
	private internalStateService = inject(InternalStateService);
	private applicationStateService = inject(ApplicationStateService);
	private applicationApiService = inject(ApplicationsApiService);
	private todoListService = inject(TodoListService);
	private routingService = inject(AppRoutingService);
	public $todoList = this.todoListService.$todoItems;
	public $reviewModeEnabled = this.internalStateService.$reviewModeEnabled;

	onClickNextButton(e: Event): void {
		e.preventDefault();
		this.saveApplication();
		this.navigateToNextTodoItem();
	}

	onClickGoToReviewDisclosures(e: Event): void {
		e.preventDefault();
		this.saveApplication();
		this.routingService.routeToApplicationScreen(KnownRoutes.ApplicationReview);
	}

	private saveApplication(): void {
		const application = this.applicationStateService.$application();
		this.applicationApiService.saveApplication(application).subscribe();
	}

	private navigateToNextTodoItem(): void {
		const currentScreenName = this.internalStateService.$currentScreen();
		const currentRouteIndex = this.$todoList().findIndex((t) => t.associatedRoute === currentScreenName);

		const nextTodoItem = this.getNextTodoItem(currentRouteIndex);
		this.routingService.routeToApplicationScreen(nextTodoItem.associatedRoute);
	}

	private getNextTodoItem(currentIndex: number): TodoItem {
		const nextTodoItem = this.$todoList()[currentIndex + 1];

		if (!nextTodoItem.visible) {
			currentIndex++;
			return this.getNextTodoItem(currentIndex);
		}

		return nextTodoItem;
	}
}
