<div class="card-div-container flex justify-center mb-6">
	<app-card
		[mainTitle]="$condition()?.name"
		[bodyText]="$condition()?.description"
		[headerIcon]="getIconFromConditionName($condition()?.name)"
		headerIconColor="blue"
		cardActionInfo="Upload"
		(click)="uploadElement.click()"
		(keydown.enter)="uploadElement.click()"
		(keydown.space)="uploadElement.click()"
		[selectable]="true"
		class="w-full">
		<app-document-upload-list [uploadList]="uploadList"></app-document-upload-list>
	</app-card>
	<form>
		<input
			multiple
			class="hidden"
			type="file"
			id="file"
			accept=".png,image/png,.jpg,.jpeg,image/jpeg,.pdf,application/pdf,.tif,.tiff,image/tiff,.gif,image/gif"
			(change)="handleFileUploads($event)"
			(click)="clearFileUploads($event)"
			#uploadElement
			data-cy="input-field" />
	</form>
</div>
