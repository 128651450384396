export const BusinessExpectationsFinanceQuestions = [
	{
		description: 'Will you be depositing or withdrawing cash in branch or by ATM?',
		promptText: 'Examples of depositing or withdrawing cash include check cashing, money orders, funds transfers, foreign exchange, prepaid cards, etc.',
		inDescription: 'What average monthly volume of cash deposits do you anticipate?',
		outDescription: 'What average monthly volume of cash withdrawals do you anticipate?',
		inLabel: 'Average Monthly Cash Deposits',
		outLabel: 'Average Monthly Cash Withdrawls',
		inControl: 'cashDeposits',
		outControl: 'cashWithdrawals',
		toggleControlName: 'cashServices',
	},
	{
		description: 'Will you be depositing checks or writing checks on this account?',
		promptText: '',
		inDescription: 'What average monthly volume of check deposits do you anticipate?',
		outDescription: 'What average monthly volume of written checks do you anticipate?',
		inLabel: 'Average Monthly Check Deposits',
		outLabel: 'Average Monthly Written Checks',
		inControl: 'incomingChecks',
		outControl: 'outgoingChecks',
		toggleControlName: 'checks',
	},
	{
		description: 'Will you be sending or receiving money through the Automated Clearing House (ACH)?',
		promptText: '',
		inDescription: 'What average monthly volume of incoming ACH activity do you anticipate?',
		outDescription: 'What average monthly volume of outgoing ACH activity do you anticipate?',
		inLabel: 'Average Monthly Incoming ACH',
		outLabel: 'Average Monthly Outgoing ACH',
		inControl: 'incomingAutomatedClearingHouse',
		outControl: 'outgoingAutomatedClearingHouse',
		toggleControlName: 'automatedClearingHouse',
	},
	{
		description: 'Will you be sending or receiving money through domestic wire transfers?',
		promptText: '',
		inDescription: 'What average monthly volume of incoming domestic wire activity do you anticipate?',
		outDescription: 'What average monthly volume of outgoing domestic wire activity do you anticipate?',
		inLabel: 'Average Monthly Incoming Domestic Wires',
		outLabel: 'Average Monthly Outgoing Domestic Wires',
		inControl: 'incomingDomesticWires',
		outControl: 'outgoingDomesticWires',
		toggleControlName: 'domesticWires',
	},
	{
		description: 'Will you be sending or receiving money through international wire transfers?',
		promptText: '',
		inDescription: 'What average monthly volume of incoming international wire activity do you anticipate?',
		outDescription: 'What average monthly volume of outgoing international wire activity do you anticipate?',
		inLabel: 'Average Monthly Incoming International Wire',
		outLabel: 'Average Monthly Outgoing International Wire',
		inControl: 'incomingInternationalWires',
		outControl: 'outgoingInternationalWires',
		toggleControlName: 'internationalWires',
	},
];

export const BusinessExpectationsTransactionRanges = [
	'$0 - $1,000',
	'$1,000 - $3,000',
	'$3,000 - $5,000',
	'$5,000 - $10,000',
	'$10,000 - $20,000',
	'$20,000 - $50,000',
	'$50,000 - $100,000',
	'$100,000 - $200,000',
	'$200,000 - $500,000',
	'$500,000 - $1,000,000',
	'$1,000,000+',
];
