import { AfterViewInit, Component, computed, inject } from '@angular/core';
import { TodoItem } from '@common/lib/models/todo-item';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { KnownValues } from '@common/lib/constants/known-values';
import { navigateActivity } from '@common/lib/constants/activities';
import { LoadingService } from '@experience/app/services/loading.service';
import { LegacyRoutingService } from '@experience/app/services/legacy-routing.service';
import { PresentationService } from '@experience/app/services/presentation.service';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { AppRoutingService } from '@experience/app/services/app-routing.service';
import { TodoListService } from '@experience/app/services/todo-list-service';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';

@Component({
	selector: 'experience-todo-list',
	templateUrl: './todo-list.component.html',
	styleUrls: ['./todo-list.component.scss'],
	animations: [
		trigger('listChanged', [
			transition('* => *', [
				// each time the binding value changes
				query(
					':enter',
					[
						style({
							opacity: 0,
						}),
						stagger(100, [
							animate(
								'500ms',
								style({
									opacity: 1,
								}),
							),
						]),
					],
					{ optional: true },
				),
				query(
					':leave',
					[
						style({
							opacity: 1,
						}),
						stagger(100, [
							animate(
								'500ms',
								style({
									opacity: 0,
								}),
							),
						]),
					],
					{ optional: true },
				),
			]),
		]),
	],
})
export class TodoListComponent implements AfterViewInit {
	private internalStateService = inject(InternalStateService);
	private presentationService = inject(PresentationService);
	private applicationStateService = inject(ApplicationStateService);
	private loadingService = inject(LoadingService);
	private legacyRoutingService = inject(LegacyRoutingService);
	private appRoutingService = inject(AppRoutingService);
	private applicationApiService = inject(ApplicationsApiService);
	private todoListService = inject(TodoListService);

	public $currentScreen = this.internalStateService.$currentScreen;
	public animationsDisabled: boolean = true;
	public $todoList = computed<TodoItem[]>(() => {
		if (this.internalStateService.$isOldExperience()) {
			const todoList = this.presentationService.todoList$.value;
			todoList.forEach((t) => (t.visible = true));
			return todoList;
		} else {
			return this.todoListService.$todoItems();
		}
	});

	public $navigationIsAllowed = computed<boolean>(() => {
		// To avoid regressions in oldfin, maintain always allowing navigation if an item is complete.
		if (this.internalStateService.$isOldExperience()) {
			return true;
		}

		return this.applicationStateService.$application()?.status === knownApplicationStatus.InProgress;
	});

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.animationsDisabled = false;
		});
	}

	public navigateToRoute(item: TodoItem): void {
		if (this.internalStateService.$isOldExperience()) {
			const { associatedRoute, complete } = item;
			if (complete && associatedRoute) {
				const fullRoute = `${KnownValues.workflowPath}${associatedRoute}`;
				this.legacyRoutingService.setCurrentRoute(associatedRoute);
				this.legacyRoutingService.navigateToUrl$(fullRoute).subscribe(() => {
					this.loadingService.stopLoadingActivity(navigateActivity);
				});
			}
		} else {
			if (this.$navigationIsAllowed() && item.complete) {
				this.applicationApiService.saveApplication(this.applicationStateService.$application()).subscribe();
				this.appRoutingService.routeToApplicationScreen(item.associatedRoute);
			}
		}
	}
}
