import { Component, inject, input, OnInit } from '@angular/core';
import { Condition } from '@experience/app/models/onboarding/condition.model';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { AppCardComponent } from '../card/app-card.component';
import { CommonModule } from '@angular/common';
import { AppDocumentUploadListComponent } from '../app-document-upload-list/app-document-upload-list.component';
import { DocumentUploadData } from '@common/lib/models/document-upload-data.model';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { NotificationService } from '@experience/app/services/notification.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';

@Component({
	selector: 'app-document-upload',
	standalone: true,
	imports: [CommonModule, AppCardComponent, AppDocumentUploadListComponent],
	templateUrl: './app-document-upload.component.html',
	styleUrl: './app-document-upload.component.scss',
})
export class AppDocumentUploadComponent implements OnInit {
	applicationsApiService = inject(ApplicationsApiService);
	applicationStateService = inject(ApplicationStateService);
	notificationService = inject(NotificationService);

	$condition = input<Condition>();
	uploadList: DocumentUploadData[] = [];

	ngOnInit(): void {
		if (this.$condition()?.uploadedFileNames?.length > 0) {
			const existingConditionFiles = [];
			for (const fileName of this.$condition().uploadedFileNames) {
				existingConditionFiles.push(new DocumentUploadData(fileName, false, true));
			}
			this.uploadList = existingConditionFiles;
		}
	}

	handleFileUploads(event: Event): void {
		const input: HTMLInputElement = event.target as HTMLInputElement;
		const files: File[] = Array.from(input.files);
		event.preventDefault();

		if (files.length > 5) {
			this.notificationService.displaySnackBarMessage('Please upload 5 files or less at one time.');
			return;
		}

		const existingCondition: Condition = this.$condition();

		for (const file of files) {
			const newDocumentUpload = new DocumentUploadData(file.name, true, false);
			newDocumentUpload.documentUploadGroupId = existingCondition.id;
			this.uploadList.push(newDocumentUpload);
		}

		this.applicationsApiService.uploadDocuments(files, this.applicationStateService.$id(), existingCondition).subscribe(
			() => {
				for (const upload of this.uploadList) {
					upload.displayUpload = false;
					upload.uploadComplete = true;
				}
				const fileNames = this.uploadList.map((upload) => upload.fileName);
				existingCondition.uploadedFileNames = fileNames;
			},
			(error: HttpErrorResponse) => {
				// Remove files from the upload list if they fail to upload
				files.forEach(() => {
					this.uploadList.pop();
				});

				if (error.status === HttpStatusCode.UnsupportedMediaType) {
					this.notificationService.displaySnackBarMessage(error.error);
				} else {
					this.notificationService.displaySnackBarMessage('Something went wrong while uploading your documents. Please try again.');
				}
			},
		);
	}

	clearFileUploads(event: Event) {
		(event.target as HTMLInputElement).value = null;
	}

	getIconFromConditionName(conditionName: string): string {
		conditionName = conditionName?.toUpperCase() ?? '';
		switch (true) {
			case conditionName.includes('ADDITIONAL IDENTITY VERIFICATION'):
				return 'fa-file-user';
			case conditionName.includes('PROOF OF ADDRESS'):
				return 'fa-map-marked-alt';
			case conditionName.includes('DRIVERS LICENSE'):
				return 'fa-address-card';
			case conditionName.includes('ID CARD'):
				return 'fa-id-card';
			case conditionName.includes('PASSPORT'):
				return 'fa-passport';
			case conditionName.includes('GUNS / AMMO LICENSE'):
				return 'fa-fighter-jet';
			case conditionName.includes('ARTICLES OF INCORPORATION'):
			case conditionName.includes('ARTICLES OF ORGANIZATION'):
			case conditionName.includes('CERTIFICATE OF PARTNERSHIP'):
			case conditionName.includes('PROOF OF EXISTENCE'):
				return 'fa-scroll-old';
			case conditionName.includes('EIN CONFIRMATION LETTER'):
				return 'fa-file-contract';
			case conditionName.includes('NAME REGISTRATION'):
				return 'fa-file-certificate';
			case conditionName.includes('VERIFY YOUR IDENTITY'):
				return 'fa-camera';
			default:
				return 'fa-file-alt';
		}
	}
}
