import { Component, inject, input, OnInit } from '@angular/core';
import alloy from '@alloyidentity/web-sdk';
import { Condition } from '@experience/app/models/onboarding/condition.model';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { AppCardComponent } from '../card/app-card.component';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { Tracker } from '@nbkc/tracker-angular';
import { VouchedActionEvent } from '@common/lib/components/identity-verification-vouched/vouched-models/vouched-action.event';
import { AppRoutingService } from '@experience/app/services/app-routing.service';
import { NotificationService } from '@experience/app/services/notification.service';
import { AlloySdkCallback } from '@experience/app/models-ui/alloy/alloy-sdk-callback.model';

@Component({
	selector: 'app-alloy-identity-verification',
	standalone: true,
	imports: [AppCardComponent],
	templateUrl: './alloy-identity-verification.component.html',
	styleUrl: './alloy-identity-verification.component.scss',
})
export class AlloyIdentityVerificationComponent implements OnInit {
	private applicationStateService = inject(ApplicationStateService);
	private applicationsApiService = inject(ApplicationsApiService);
	private notificationService = inject(NotificationService);
	private routingService = inject(AppRoutingService);
	private tracker = inject(Tracker);
	private config = inject(ExperienceConfiguration);
	public $condition = input<Condition>();

	async ngOnInit(): Promise<void> {
		await alloy.init({
			key: this.config.alloySdkPublicKey,
			production: this.config.alloySdkUseProduction,
			journeyToken: this.config.alloyJourneyToken,
			journeyApplicationToken: this.applicationStateService.$application().kycProviderId,
			customStyle: {
				theme: {
					primaryColor: '#1075f1',
				},
				componentOverride: undefined,
			},
		});
	}

	public startVerification(): void {
		this.tracker.event(new VouchedActionEvent('verification-started'));
		alloy.open(this.onAlloyCompleteCallback.bind(this), 'anchor');
	}

	private onAlloyCompleteCallback(callbackData: AlloySdkCallback) {
		// eslint-disable-next-line no-underscore-dangle
		const relatedEntity = callbackData._embedded.child_entities?.find((e) => e.external_entity_identifier === this.$condition().applicantId);
		if (!relatedEntity) {
			this.tracker.event(new VouchedActionEvent('verification-failed'));
			return;
		} else if (relatedEntity.entity_application_status === 'pending_step_up') {
			// If the entity is still in a 'pending_step_up' status, it means they exited and did not complete verification.
			this.tracker.event(new VouchedActionEvent('verification-abandoned'));
			return;
		}

		this.tracker.event(new VouchedActionEvent('verification-completed'));
		this.applicationsApiService.identityVerified(this.applicationStateService.$id(), this.$condition().id).subscribe(
			() => {
				this.routingService.routeByApplicationStatus(this.applicationStateService.$application().status);
			},
			() => {
				this.notificationService.displaySnackBarMessage('Something went wrong verifying your Id.');
			},
		);
	}
}
