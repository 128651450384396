import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { ApplicationStateService } from '../application-state.service';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';
import { KnownRoutes } from '@experience/app/constants/known-routes';
import { ApplicationKind } from '@experience/app/models/enums/application-kind';
import { AppSession } from '@experience/app/app.session';
import { ApplicationsApiService } from '../applications-api.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export const ApplicationRouteGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot, // eslint-disable-line @typescript-eslint/no-unused-vars
): Observable<boolean> => {
	const applicationsApiService = inject(ApplicationsApiService);
	const applicationStateService = inject(ApplicationStateService);

	// If no application in state, go get it before checking if route is valid
	if (!applicationStateService.$application()?.id) {
		const appSession = inject(AppSession);
		let applicationId: string;

		appSession.data$.subscribe((o) => {
			applicationId = o.applicationId;
		});

		if (!applicationId) {
			return of(false);
		}

		return applicationsApiService.getApplication(applicationId).pipe(
			switchMap((application) => {
				const kind = application.kind;
				return forkJoin([
					applicationsApiService.getLookups(kind),
					applicationsApiService.getProducts(kind),
				]).pipe(map(() => isValidRoute(route, applicationStateService)));
			}),
		);
	} else {
		return of(isValidRoute(route, applicationStateService));
	}
};

export const isValidRoute = (
	route: ActivatedRouteSnapshot,
	applicationStateService: ApplicationStateService,
): boolean => {
	// If route has any parameters, ensure we only compare against the route name.
	const attemptedRoute = route.routeConfig.path.split('/')[0];
	const status = applicationStateService.$application().status;

	if (status === knownApplicationStatus.InProgress) {
		const preSubmitRoutes = getPreSubmitRoutes(applicationStateService.$application().kind);

		if (!preSubmitRoutes.includes(attemptedRoute)) {
			return false;
		}
	} else {
		const expectedRoute = getExpectedPostSubmissionRoute(status);

		if (expectedRoute !== attemptedRoute) {
			return false;
		}
	}

	return true;
};

export const getPreSubmitRoutes = (applicationKind: ApplicationKind): string[] => {
	switch (applicationKind) {
		case ApplicationKind.Personal: {
			return [
				KnownRoutes.AddApplicants,
				KnownRoutes.ApplicantDetails,
				KnownRoutes.DebitCard,
				KnownRoutes.ApplicantDetails,
				KnownRoutes.PrimaryApplicant,
				KnownRoutes.ProductSelection,
				KnownRoutes.ApplicationReview,
			];
		}
		case ApplicationKind.Business: {
			return [
				KnownRoutes.AddApplicants,
				KnownRoutes.ApplicantDetails,
				KnownRoutes.DebitCard,
				KnownRoutes.ApplicantDetails,
				KnownRoutes.PrimaryApplicant,
				KnownRoutes.ProductSelection,
				KnownRoutes.BusinessApplicant,
				KnownRoutes.BusinessExpectations,
				KnownRoutes.ProhibitedBusinesses,
				KnownRoutes.ApplicationReview,
			];
		}
		default:
			return [];
	}
};

export const getExpectedPostSubmissionRoute = (applicationStatus: string): string => {
	// For post-submission statuses, there is only one route apps should be allowed to.
	switch (applicationStatus) {
		case knownApplicationStatus.Approved:
			return KnownRoutes.SetupOnlineBanking;
		case knownApplicationStatus.Denied:
			return KnownRoutes.Denied;
		case knownApplicationStatus.InReview:
			return KnownRoutes.ManualReview;
		case knownApplicationStatus.OnHold:
			return KnownRoutes.OnHold;
		default:
			return 'nowhere :)';
	}
};
