import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { AppRoutingService } from '@experience/app/services/app-routing.service';
import { TodoItem } from '@common/lib/models/todo-item';
import { TodoListService } from '@experience/app/services/todo-list-service';

@Component({
	selector: 'app-todo-header',
	standalone: true,
	imports: [CommonModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './app-todo-header.component.html',
	styleUrl: './app-todo-header.component.scss',
})
export class AppTodoHeaderComponent {
	private internalStateService = inject(InternalStateService);
	private applicationApiService = inject(ApplicationsApiService);
	private applicationStateService = inject(ApplicationStateService);
	private todoListService = inject(TodoListService);
	private routingService = inject(AppRoutingService);

	public $todoList = this.todoListService.$todoItems;
	public $hideBackButton = input<boolean>(false);

	$currentRouteIndex = computed<number>(() => {
		const currentScreenName = this.internalStateService.$currentScreen();
		const currentRouteIndex = this.$todoList().findIndex((t) => t.associatedRoute === currentScreenName);
		return currentRouteIndex;
	});

	$title = computed<string>(() => this.$todoList()[this.$currentRouteIndex()]?.title);
	$subtitle = computed<string>(() => this.$todoList()[this.$currentRouteIndex()]?.subtitle);

	navigateBack($event): void {
		$event.preventDefault();

		const application = this.applicationStateService.$application();

		this.applicationApiService.saveApplication(application).subscribe();
		const previousTodoItem = this.getPreviousTodoItem(this.$currentRouteIndex());
		this.routingService.routeToApplicationScreen(previousTodoItem.associatedRoute);
	}

	private getPreviousTodoItem(currentIndex): TodoItem {
		const previousTodoItem = this.$todoList()[currentIndex - 1];

		if (!previousTodoItem.visible) {
			currentIndex--;
			return this.getPreviousTodoItem(currentIndex);
		}

		return previousTodoItem;
	}
}
