@if ($todoList().length > 0) {
	<div class="todo-list absolute">
		<div [@.disabled]="animationsDisabled">
			<ul [@listChanged]="$todoList()">
				<li>
					<div class="todo-header mb-8 font-semibold leading-12">To Do:</div>
				</li>
				@for (item of $todoList(); track $index) {
					@if (item.visible) {
						<li>
							<div
								class="flex items-center mb-6 transform text-base transition duration-300 hover:scale-125"
								[class.cursor-pointer]="item.complete && $navigationIsAllowed()"
								[class.cursor-not-allowed]="!item.complete || !$navigationIsAllowed()"
								[class.active-screen]="item.associatedRoute === $currentScreen()"
								(click)="navigateToRoute(item)">
								<div
									[ngClass]="{ invisible: !item.complete }"
									class="fas fa-check-circle mr-4"></div>
								<div [class.nbkc-dark-gray]="item.associatedRoute !== $currentScreen()">
									{{ item.title }}
								</div>
							</div>
						</li>
					}
				}
			</ul>
		</div>
	</div>
}
