import { Component, OnInit, inject, computed, signal } from '@angular/core';
import { PresentationDefinition } from '@common/lib/models/presentation-definition';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from '@common/lib/animations/slide-in-out';
import { PresentationService } from '@experience/app/services/presentation.service';
import { AnimationCache } from '@experience/app/models/animation-cache.model';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';
import { TodoListService } from '@experience/app/services/todo-list-service';

@Component({
	selector: 'experience-body',
	templateUrl: './body.component.html',
	styleUrls: ['./body.component.scss'],
	animations: [slideInAnimation],
})
export class BodyComponent implements OnInit {
	private applicationStateService = inject(ApplicationStateService);
	private presentationService = inject(PresentationService);
	private todoListService = inject(TodoListService);

	public presentationDefinition$: PresentationDefinition = null;
	public options: BodyComponentOptions;
	public $animationDirection = signal<string>('');
	public $hiddenTodoList = computed<boolean>(
		() =>
			!this.applicationStateService.$id() ||
			this.$application().status === knownApplicationStatus.OnHold ||
			this.$application().status === knownApplicationStatus.Denied,
	);

	private animationCache: AnimationCache = {
		direction: null,
		screenIndex: 0,
	};

	private $application = this.applicationStateService.$application;

	constructor() {}

	ngOnInit(): void {
		this.presentationDefinition$ = this.presentationService.getCurrentPresentationDefinition();

		this.presentationService.currentPresentationDefinition$.subscribe((presentationDefinition) => {
			this.options = {
				containerClass: presentationDefinition?.body?.options?.containerClass,
				hideTodoList: presentationDefinition?.body?.options?.hideTodoList,
			};
		});
	}

	public onRouterOutletActivated(outlet: RouterOutlet) {
		const currentScreenIndex = this.getScreenIndexFromRouterOutlet(outlet);
		if (outlet?.isActivated && this.animationCache.screenIndex !== currentScreenIndex) {
			this.animationCache = {
				direction: currentScreenIndex > this.animationCache.screenIndex ? 'forward' : 'back',
				screenIndex: currentScreenIndex,
			};
		}

		this.$animationDirection.set(this.animationCache.direction);
	}

	public doneAnimating(): void {
		this.animationCache.direction = null;
		this.$animationDirection.set(null);
	}

	private getScreenIndexFromRouterOutlet(outlet: RouterOutlet): number {
		// Oldfin stores the screen index on the route. This logic can be removed once the refactor is released.
		if (outlet.isActivated && outlet?.activatedRouteData.screenIndex) {
			return outlet.activatedRouteData.screenIndex;
		}

		if (outlet.activatedRoute?.snapshot?.routeConfig?.path) {
			const screen = outlet.activatedRoute?.snapshot?.routeConfig?.path;
			return this.todoListService.$todoItems().findIndex((t) => t.associatedRoute === screen);
		}

		// This should not happen, but default to 0 as this logic is only to help animations and accuracy is not critical.
		return 0;
	}
}

interface BodyComponentOptions {
	containerClass: string;
	hideTodoList: string;
}
