import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
	static minAge(control: AbstractControl): ValidationErrors | null {
		const inputDate = new Date(control.value);
		const today = new Date();

		if (!control.value) {
			return null; // No value, so no validation error
		}

		// Calculate the age based on the input date
		let age = today.getFullYear() - inputDate.getFullYear();
		const monthDifference = today.getMonth() - inputDate.getMonth();
		const dayDifference = today.getDate() - inputDate.getDate();

		// Adjust age if the month/day hasn't passed yet this year
		if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
			age--;
		}

		// Check if age is at least the minimum required
		return age >= 18 ? null : { minAge: true };
	}

	static maxAge(control: AbstractControl): ValidationErrors | null {
		const selectedDate = new Date(control.value);
		const today = new Date();

		// Calculate the maximum valid date by subtracting the maximum age
		const maxDate = new Date(today.getFullYear() - 115, today.getMonth(), today.getDate());

		if (!control.value || selectedDate >= maxDate) {
			return null; // Valid date
		}

		return { maxAge: true }; // Invalid date
	}

	static unexpiredId(control: AbstractControl): ValidationErrors | null {
		const selectedDate = new Date(control.value);
		const today = new Date();

		// Set today's time to 00:00:00 to only compare the date part
		today.setHours(0, 0, 0, 0);

		if (!control.value || selectedDate >= today) {
			return null; // Valid date
		}

		return { expiredId: true }; // Invalid date
	}

	static maxIdExpirationDate(control: AbstractControl): ValidationErrors | null {
		const selectedDate = new Date(control.value);
		const fiftyYearsFromNow = new Date();
		fiftyYearsFromNow.setHours(0, 0, 0, 0);
		fiftyYearsFromNow.setFullYear(fiftyYearsFromNow.getFullYear() + 50);

		if (!control.value || selectedDate <= fiftyYearsFromNow) {
			return null; // Valid date
		}

		return { maxIdExpirationDate: true }; // Invalid date
	}
}
