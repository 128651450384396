import { InputMaskConfig } from '@experience/app/models-ui/input-mask-config';

export const TEXT_MASK_CONFIGS: Record<string, InputMaskConfig> = {
	percent: {
		mask: '9||99',
		config: {
			suffix: '%',
			validation: true,
		},
	},

	singleLetter: {
		mask: 'S', // Single alphabetic character mask
		config: {
			validation: true,
		},
	},

	decimal: {
		mask: 'separator.2', // Decimal number mask
		config: {
			separatorLimit: '8',
			thousandSeparator: ',',
			validation: true,
		},
	},

	// eslint-disable-next-line id-blacklist
	number: {
		mask: 'separator', // Integer number mask without decimals
		config: {
			separatorLimit: '8',
			thousandSeparator: ',',
			validation: true,
		},
	},

	zip: {
		mask: '00000', // 5-digit US zip code mask
		config: {
			validation: true,
		},
	},

	age: {
		mask: '00', // Age mask (2 digits)
		config: {
			validation: true,
		},
	},

	securityCode: {
		mask: '000', // 3-digit security code mask
		config: {
			validation: true,
		},
	},

	fourDigitCurrency: {
		mask: 'separator.2', // Currency mask with 2 decimal points
		config: {
			prefix: '$',
			separatorLimit: '4',
			thousandSeparator: ',',
			validation: true,
		},
	},

	phone: {
		mask: '(000) 000-0000', // US phone number mask
		config: {
			validation: true,
		},
	},

	ssn: {
		mask: 'XXX-XX-0000', // US Social Security Number mask
		config: {
			patterns: {
				X: { pattern: new RegExp('^\\d*'), symbol: '*' },
				0: { pattern: new RegExp('\\d') },
			},
			validation: true,
		},
	},

	ein: {
		mask: '00-0000000', // EIN (Employer Identification Number) mask
		config: {
			patterns: {
				X: { pattern: new RegExp('^\\d'), symbol: '*' },
				0: { pattern: new RegExp('\\d') },
			},
			validation: true,
		},
	},

	currency: {
		mask: 'separator.2', // Currency mask with 2 decimal points
		config: {
			prefix: '$',
			separatorLimit: '8',
			thousandSeparator: ',',
			validation: true,
		},
	},

	date: {
		mask: 'd0/m0/0000', // Date format (MM/DD/YYYY)
		config: {
			leadZeroDateTime: true,
			validation: true,
		},
	},

	monthYearDate: {
		mask: 'd0/00', // Month/Year date format (MM/YY)
		config: {
			leadZeroDateTime: true,
			validation: true,
		},
	},

	creditCardNumber: {
		mask: '0000 0000 0000 0000', // Credit card number mask (16 digits)
		config: {
			validation: true,
		},
	},

	amexCreditCardNumber: {
		mask: '0000 000000 00000', // Amex card number mask (15 digits)
		config: {
			validation: true,
		},
	},
};
