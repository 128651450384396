<app-todo-header></app-todo-header>
@if ($products().length) {
	@for (product of $products(); track product.id) {
		<div class="select-container flex justify-center mb-4">
			<app-card
				[mainTitle]="product.name"
				[headerIcon]="getProductIcon(product.name)"
				[headerRate]="product?.rate | number: '1.2-2'"
				[hasCornerBanner]="product.selected"
				[bodyText]="product?.displayText"
				[selectable]="true"
				[toggleable]="true"
				[$selected]="product?.selected"
				(click)="onProductCardClicked(product.id)"
				(keydown.enter)="onProductCardClicked(product.id)"
				(keydown.space)="onProductCardClicked(product.id, $event)"
				data-cy="selectable-card"
				class="w-full cursor-pointer"></app-card>
		</div>
	}
}

<app-next-todo-button></app-next-todo-button>
